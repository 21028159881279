<template>
  <div>
    <div v-if="asDialog">
      <v-btn
        :color="buttonColor"
        prepend-icon="fas fa-images"
        :text="t('images.title', 2)"
        class="float-right"
        :class="`text-${fontColor}`"
        @click="dialog = true"
      />
      <v-dialog
        v-model="dialog"
        max-width="800px"
        :fullscreen="xs"
      >
        <global-card
          :title="t('images.location')"
          closable
          @close="dialog = false"
        >
          <v-carousel
            v-model="imageIndex"
            :show-arrows="showArrows"
            :hide-delimiters="!showArrows"
          >
            <v-carousel-item
              v-for="(item, i) in images"
              :key="i"
              :src="item.href"
              @click="showImage(item)"
            />
          </v-carousel>
          <template #actions>
            <v-btn
              :text="t('actions.close')"
              color="dark-grey"
              @click="dialog = false"
            />
          </template>
        </global-card>
      </v-dialog>
    </div>
    <div v-else>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        :fullscreen="xs"
      >
        <global-card
          :title="t('cart.title')"
          closable
          @close="dialog = false"
        >
          <v-img
            :src="visibleItem?.href"
          />
          <template #actions>
            <v-btn
              :text="t('actions.close')"
              color="dark-grey"
              @click="dialog = false"
            />
          </template>
        </global-card>
      </v-dialog>
      <v-carousel
        v-model="imageIndex"
        :show-arrows="showArrows"
        :hide-delimiters="!showArrows"
        :height="null"
      >
        <v-carousel-item
          v-for="(item, i) in images"
          :key="i"
          :src="item.href"
          @click="showImage(item)"
        />
      </v-carousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const props = withDefaults(defineProps<{
  images?: Array<Partial<ImageForSlideShow>>;
  asDialog?: boolean;
  buttonColor?: string;
  fontColor?: string;
  showArrows?: boolean;
  canZoom?: boolean;
}>(), {
  images: () => [],
  asDialog: () => false,
  buttonColor: () => 'secondary',
  fontColor: () => 'primary-darken-1',
  showArrows: () => true,
  canZoom: () => false,
});

const imageIndex = defineModel();

const { smAndDown, xs } = useDisplay();
const { t } = useI18n();
const dialog = ref(false);
const visibleItem = ref();

function showImage(item) {
  if (props.canZoom) {
    visibleItem.value = item;
    dialog.value = true;
  }
}

interface ImageForSlideShow {
  href: string;
}
</script>

<template>
  <div>
    <breadcrumb-header
      :items="[{ title: t('permission.denied') }]"
    />
    <v-btn
      size="x-large"
      to="/"
      :text="t('global.home')"
      color="primary"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <div>
    <v-text-field
      ref="pwd"
      v-model="password"
      :type="(showPassword) ? 'text' : 'password'"
      :name="name"
      :label="label"
      data-testid="password"
      autocomplete="on"
      :hint="hint"
      :persistent-hint="persistentHint"
      :error="error"
      :error-messages="errorMessages"
    >
      <template #append-inner>
        <v-btn
          color="primary"
          size="small"
          data-testid="password-toggle"
          tabindex="-1"
          @click="toggleShowPassword"
        >
          <v-icon
            :icon="icon"
          />
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

withDefaults(defineProps<{
  name: string;
  label?: string;
  error?: boolean;
  errorMessages?: string[];
  hint?: string;
  persistentHint?: boolean;
}>(), {
  label: () => useI18n().t('password.title'),
  persistentHint: () => false,
});
const showPassword = ref(false);
const pwd = ref<HTMLInputElement | null>(null);
const icon = computed(() => (showPassword.value ? 'far fa-eye-slash' : 'far fa-eye'));
const password = defineModel<string>();

function toggleShowPassword() {
  showPassword.value = !showPassword.value;
  pwd.value?.focus();
}
</script>

<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/before-shifts/day-overseers"
    />
    <div v-if="dayOptions && type == 'day'">
      <div
        v-for="(day, index) in dayOptions"
        :key="index"
      >
        <div class="text-h5 mb-2">
          {{ t(`date.${index.replace(' ', '_').toLowerCase()}`) }}
        </div>
        <v-row>
          <v-col
            v-for="(dayOverseer, dayOverseerIndex) in day"
            :key="dayOverseerIndex"
            class="text-center"
            cols="12"
            sm="6"
            md="4"
          >
            <day-overseer-card :day-overseer="dayOverseer" />
          </v-col>
        </v-row>
        <v-divider
          class="my-4"
        />
      </div>
    </div>
    <div v-if="locationOptions && type == 'location'">
      <div
        v-for="(location, index) in locationOptions"
        :key="index"
      >
        <div class="text-h5 mb-2">
          {{ location.name }}
        </div>
        <v-row>
          <v-col
            v-for="(dayOverseer, dayOverseerIndex) in location.day_overseers.filter(dayOverseer => dayOverseer.day_of_week === new Date().getDay())"
            :key="dayOverseerIndex"
            class="text-center"
            cols="12"
            sm="6"
            md="4"
          >
            <day-overseer-card
              :day-overseer="dayOverseer"
              title="today"
            />
          </v-col>
          <v-col
            v-for="(dayOverseer, dayOverseerIndex) in location.day_overseers"
            :key="dayOverseerIndex"
            class="text-center"
            cols="12"
            sm="6"
            md="4"
          >
            <day-overseer-card
              :day-overseer="dayOverseer"
              title="dow"
            />
          </v-col>
        </v-row>
        <v-divider
          class="my-4"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSettings } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import DayOverseerCard from './DayOverseerCard.vue';

const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const breadcrumbs = [{ title: t('dayoverseer.title', 2) }];

interface DayOverseerList {
  Monday: App.Models.DayOverseer[];
  Tuesday: App.Models.DayOverseer[];
  Wednesday: App.Models.DayOverseer[];
  Thursday: App.Models.DayOverseer[];
  Friday: App.Models.DayOverseer[];
  Saturday: App.Models.DayOverseer[];
  Sunday: App.Models.DayOverseer[];
}

const dayOptions = ref<DayOverseerList>();
const locationOptions = ref();

const { getSetting } = useSettings();

const type = getSetting('day_overseer_display_type');
async function loadData() {
  try {
    const response = await axios.get('/api/dayoverseers');
    dayOptions.value = response.data.data;
    const locationResponse = await axios.get('/api/dayoverseers/locations');
    locationOptions.value = locationResponse.data.data;
  }
  catch (error) {
    showSnackMessage(t('dayoverseer.cannot_load'));
  }
}

loadData();
</script>

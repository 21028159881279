<template>
  <publisher-card-action
    v-if="asCardAction"
    icon="fas fa-plus-square"
    :caption="t('actions.uncancel')"
    @click="uncancellationDialog = true"
  />
  <v-btn
    v-else
    :text="t('actions.uncancel')"
    color="primary"
    block
    size="default"
    @click="uncancellationDialog = true"
  />
  <v-dialog
    v-model="uncancellationDialog"
    max-width="500px"
  >
    <global-card
      :title="t('shift.uncancel')"
    >
      <v-card-text v-if="!canUncancelShifts">
        {{ t('shift.uncancel_warning', { weekdayName: weekdayName }) }}
      </v-card-text>
      <template #actions>
        <v-btn
          color="primary"
          :text="t('shift.dont_uncancel')"
          @click="uncancellationDialog = false"
        />
        <v-btn
          v-if="canUncancelShifts"
          :text="t('shift.uncancel')"
          color="success"
          @click="uncancelShift"
        />
      </template>
    </global-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  useDate,
  usePermissions,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PublisherCardAction from '../scheduler/PublisherCardAction.vue';

const props = defineProps<{
  user: App.Models.User;
  shift: App.ApiResources.PublisherWithShiftDetailsResource;
  timeSlotIndex?: string;
  location?: App.Models.Location;
  asCardAction?: boolean;
}>();

const emit = defineEmits(['shift:uncancelled']);

const { t } = useI18n();

const { showSnackMessage } = useErrorStore();
const { can } = usePermissions();
const { toLongDay } = useDate();
const uncancellationDialog = ref(false);
const actionDescription = defineModel<string>('actionDescription', { default: '' });
const actionInProgress = defineModel<number>('actionInProgress', { default: 0 });

const canUncancelShifts = computed<boolean>(() => can('manage-shifts'));
const weekdayName = computed<string>(() => {
  if (props.shift.is_holiday === true) {
    return t('date.bank_holiday');
  }
  return toLongDay(props.shift.date);
});

async function uncancelShift() {
  try {
    actionDescription.value = t('states.uncancelling');
    actionInProgress.value += 1;
    await axios.patch(`/api/users/${props.user.id}/shifts/${props.shift.shift_id ?? props.shift.id}`, {
      as_captain: false,
      is_exception: false,
    }, { params: { action: 'assign' } });

    showSnackMessage(t('shift.assigned'), 'success');
    emit('shift:uncancelled');
  }
  catch (error) {
    console.error(error);
    showSnackMessage(t('shift.uncancel_error'));
  }
  finally {
    uncancellationDialog.value = false;
    actionInProgress.value -= 1;
  }
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="750"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        prepend-icon="fas fa-eye"
        color="primary"
        :text="t('news.read')"
      />
    </template>
    <news-article
      v-if="news"
      :article="news"
      :closable="true"
      @close="dialog = false"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import NewsArticle from './NewsArticle.vue';

const properties = defineProps<{
  model: App.Models.News;
}>();

const { t } = useI18n();

const dialog = ref(false);
const { showSnackMessage } = useErrorStore();

const news = ref<App.Models.News | undefined>();

async function fetch() {
  try {
    /* @ts-ignore */
    const result = await axios.get(`/api/news/${properties.model.id}`);
    news.value = result.data.data;
  }
  catch (error) {
    showSnackMessage(t('news.cannot_load'));
  }
}

watch(() => dialog.value, (value) => {
  if (value) {
    fetch();
  }
});
</script>

<template>
  <div>
    <breadcrumb-header
      v-if="withBreadcrumbs"
      :items="breadcrumbs"
    />
    <div
      v-if="(loadingCount > 0) || !shift"
      class="my-5"
    >
      <loading-spinner />
    </div>
    <div v-else>
      <v-alert
        v-if="!shift.display_contacts"
        type="info"
      >
        {{ t('shift.detail.hint', { days: contactsVisibleForDays, word_for_days: t('date.day', contactsVisibleForDays).toLocaleLowerCase() }) }}
      </v-alert>

      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <global-card
            class="mt-3"
            :title="t('global.details')"
            :with-actions="false"
            fill-height
          >
            <template #image>
              <v-img
                :src="locationHeroImage"
                class="align-end"
                cover
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              >
                <v-card-title class="text-white">
                  {{ shift?.location?.name }}
                  <br>
                  {{ toLongDateWithDay(shift.date) }}
                </v-card-title>
              </v-img>
            </template>
            <div class="mb-1">
              <v-icon
                color="primary"
                :icon="`fas ${shift.previous ? 'fa-arrow-circle-down' : 'fa-arrow-circle-right'}`"
              />
              <v-icon
                color="primary"
                icon="fas fa-suitcase-rolling"
              />
              {{ t('shift.before') }}: {{ shift.previous ? t('global.yes') : t('global.no') }}
            </div>
            <div class="mb-1 text-font">
              <v-icon icon="far fa-clock" />
              {{ shiftTime }}
            </div>
            <div class="mb-1">
              <v-icon
                color="primary"
                :icon="`fas ${shift.next ? 'fa-arrow-circle-down' : 'fa-arrow-circle-left'}`"
              />
              <v-icon
                color="primary"
                icon="fas fa-suitcase-rolling"
              />
              {{ t('shift.next') }}: {{ shift.next ? t('global.yes') : t('global.no') }}
            </div>
          </global-card>
        </v-col>
        <v-col
          v-if="shift.captain"
          cols="12"
          sm="6"
        >
          <member-card
            :user="shift.captain"
            :title="getSetting('captain_label')"
            :show-email="false"
            fill-height
          />
        </v-col>
        <template v-if="dayOverseerType === 'location' && shift.location.day_overseers.length">
          <v-col
            v-for="(dayOverseer) in shift.location.day_overseers"
            :key="dayOverseer.id"
            cols="12"
            sm="6"
            md="4"
          >
            <member-card
              :user="dayOverseer"
              :title="t('dayoverseer.title')"
              :show-email="false"
              fill-height
            />
          </v-col>
        </template>
        <template v-if="dayOverseerType === 'day' || !shift.location.day_overseers.length">
          <v-col
            v-for="(dayOverseer) in shift.day_overseers"
            :key="dayOverseer.id"
            cols="12"
            sm="6"
            md="4"
          >
            <member-card
              :user="dayOverseer"
              :title="`${dayOverseer.abbreviated_order_name} ${t('dayoverseer.title')}`"
              :show-email="false"
              fill-height
            />
          </v-col>
        </template>

        <v-col
          v-if="shift.weather_forecast"
          cols="12"
          sm="6"
          md="4"
        >
          <global-card
            :title="t('system_info.weather_forecast')"
            class="mt-3 text-center"
            :with-actions="false"
          >
            <div class="d-flex flex-column">
              <div class="text-uppercase text-font">
                <v-icon
                  class="mt-2 me-2"
                  :icon="`wi wi-owm-${(shift.weather_forecast.is_night ? 'night' : 'day')}-${shift.weather_forecast.condition_id}`"
                />
                <div>
                  {{ shift.weather_forecast.condition_desc }}
                </div>
              </div>
              <div>
                <v-icon
                  icon="fas fa-thermometer-half"
                  class="text-font me-2"
                /> {{ parseFloat(shift.weather_forecast.temperature.toString()).toFixed(1) }}
                &deg;C
              </div>
              <div>
                <v-icon
                  icon="fas fa-wind"
                  class="me-2 text-font"
                /><span class="text-font"> {{ (parseFloat(shift.weather_forecast.wind_speed.toString()) * 2.23694).toFixed(1)
                }} {{ t('global.mph') }}</span>
              </div>
            </div>
          </global-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <global-card
            :title="t('shift.detail.important_notes')"
            :with-actions="false"
          >
            <div class="my-2" />
            <shift-tag
              v-for="(tag, index) in shift.tags"
              :key="index"
              size="large"
              class="mb-2"
              :shift-tag="tag"
            />

            <ul class="ms-2">
              <li v-if="userIsCaptain">
                <strong>{{ t('shift.detail.captain_hint_1', { captain: getSetting('captain_label') }) }}</strong>
                {{ t('shift.detail.captain_hint_2', { captain: getSetting('captain_label') }) }}
                <div
                  v-if="captainDuties"
                >
                  <v-dialog v-model="captainDutiesDialog">
                    <template #activator="{ props: activatorProps }">
                      <v-btn
                        v-bind="activatorProps"
                        color="primary"
                        class="my-1"
                        :text="t('shift.detail.duties', { captain: getSetting('captain_label') })"
                      />
                    </template>
                    <global-card
                      :title="t('shift.detail.duties', { captain: getSetting('captain_label') })"
                      closable
                      @close="captainDutiesDialog = false"
                    >
                      <div
                        class="tiptap text-font"
                        v-html="captainDuties"
                      />
                    </global-card>
                  </v-dialog>
                </div>
              </li>
              <li v-if="!shift.previous">
                <strong>{{ t('shift.detail.collect_cart_title') }}</strong>
                {{ t('shift.detail.collect_cart_hint') }}
              </li>
              <li v-if="shift.previous">
                <strong>{{ t('shift.detail.previous_shift_title') }}</strong>
                {{ t('shift.detail.previous_shift_hint') }}
              </li>
              <li v-if="!shift.next">
                <strong>{{ t('shift.detail.return_cart_title') }}</strong>
                {{ t('shift.detail.return_cart_hint') }}
              </li>
              <li v-if="shift.next">
                <strong>{{ t('shift.detail.next_shift_title') }}</strong>
                {{ t('shift.detail.next_shift_hint') }}
              </li>
            </ul>
          </global-card>
        </v-col>
        <v-col
          v-if="shift.previous"
          cols="12"
          sm="6"
          md="4"
        >
          <member-card
            v-if="shift.display_contacts && shift.previous.captain"
            :title="t('global.previous_captain', { captain: getSetting('captain_label').toLowerCase() })"
            :user="shift.previous.captain"
          />
        </v-col>
        <v-col
          v-if="shift.next"
          cols="12"
          sm="6"
          md="4"
        >
          <member-card
            v-if="shift.display_contacts && shift.next.captain"
            :title="t('global.next_captain', { captain: getSetting('captain_label').toLowerCase() })"
            :user="shift.next.captain"
            :show-email="false"
          />
        </v-col>
      </v-row>

      <template v-if="shift.display_contacts">
        <h4 class="text-h6 mt-5">
          {{ t('shift.detail.contacts', 2) }}
        </h4>
        <v-row>
          <v-col
            v-for="(publisher, index) in shift.publishers"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <member-card
              :user="publisher"
              :show-email="false"
              :show-heading="false"
            />
          </v-col>
        </v-row>
      </template>

      <v-divider class="my-5" />
      <location-details
        v-if="shift.location"
        :location-id="shift.location.id"
        :shift-id="(shiftIsTodayOrTomorrow) ? shift.id : undefined"
        :show-breadcrumbs="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import GlobalCard from '@/components/globals/GlobalCard.vue';
import { useAuth, useDate, useSettings } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import {
  computed,
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import LocationDetails from './locations/LocationDetails.vue';
import ShiftTag from './ShiftTag.vue';
import MemberCard from './teams/MemberCard.vue';

const props = withDefaults(defineProps<{
  shiftId: number | string;
  withBreadcrumbs?: boolean;
}>(), {
  withBreadcrumbs: () => true,
});

const { t } = useI18n();
const { getSetting } = useSettings();
const dayOverseerType = getSetting('day_overseer_display_type');

const { showSnackMessage } = useErrorStore();

const breadcrumbs = [{ title: t('shift.details') }];

const {
  toShortTime,
  toLongDateWithDay,
  isTodayOrTomorrow,
  contactsVisibleForDays,
} = useDate();

const { authUser } = useAuth();

const shift = ref<App.ApiResources.ShiftResource>();

const captainDutiesDialog = ref(false);
const captainDuties = ref('');
const loadingCount = ref(0);

const shiftIsTodayOrTomorrow = computed(() => isTodayOrTomorrow(`${shift.value?.date}T00:00`));

const shiftTime = computed(() => {
  if (shift.value) {
    return `${shift.value?.band?.name} ${toShortTime(shift.value?.begins)} - ${toShortTime(shift.value?.ends)}`;
  }
  return null;
});

const locationHeroImage = computed(() => shift.value?.location?.hero_image?.signed_file_url ?? '/storage/images/defaut_location_hero.jpg');

const userIsCaptain = computed(() => shift.value?.captain?.id === authUser.id);

async function loadShiftDetails() {
  loadingCount.value += 1;
  try {
    const response = await axios.get(`/api/shifts/${props.shiftId}`);
    shift.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('shift.detail.cannot_load'));
  }
  finally {
    loadingCount.value -= 1;
  }
}

async function loadCaptainDuties() {
  const captainDutiesFaqId = getSetting('captain_duties_faq_id');
  if (captainDutiesFaqId) {
    try {
      const response = await axios.get(`/api/faqs/${captainDutiesFaqId}`);
      captainDuties.value = response.data?.data?.answer;
    }
    catch (error) {
      console.error(t('shift.detail.cannot_load_captain_duties'));
    }
  }
}

onBeforeMount(() => {
  loadShiftDetails();
});

watch(() => shift.value?.captain, () => {
  if (userIsCaptain.value) {
    loadCaptainDuties();
  }
});
</script>

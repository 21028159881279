import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuth } from '../composables/useAuth';

const { isLoggedIn } = useAuth();

function requiresAuth(route: RouteLocationNormalized): boolean {
  return route.matched.some((record) => {
    const { meta } = record;
    return meta.requiresAuth ?? true; // if nothing is configured, then require authentication by default
  });
}

/**
 * Show login view if the current user is not logged in.
 */
export async function loginGuard(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  // If login is enabled, route logged out users to the login view and logged in users to the content
  if (to.name === 'login' && isLoggedIn()) {
    next({ path: '/' });
  }
  else if (requiresAuth(to) && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }
  else {
    next();
  }
}

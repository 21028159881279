<template>
  <breadcrumb-header
    :items="breadcrumbs"
    help-link="/docs/publisher/managing-shifts/calendar"
  />
  <div v-if="user">
    <v-tabs
      v-if="user.spouse && spouseCan('book-shifts')"
      v-model="tab"
    >
      <smpw-tab
        :active="tab === 'mycalendar'"
        :text="t('calendar.mine')"
        value="mycalendar"
      />
      <smpw-tab
        v-if="user.spouse && spouseCan('book-shifts')"
        :active="tab === 'spousecalendar'"
        :text="t('calendar.spouses')"
        value="spousecalendar"
      />
    </v-tabs>

    <v-window
      v-model="tab"
      :touch="false"
    >
      <v-window-item value="mycalendar">
        <shift-calendar
          ref="userCalendar"
          class="mb-4"
          :user="user"
          @data:updated="spouseCalendar?.refreshCalendarData()"
        />
      </v-window-item>
      <v-window-item
        v-if="user.spouse"
        value="spousecalendar"
      >
        <shift-calendar
          ref="spouseCalendar"
          class="mb-4"
          :user="user.spouse"
          @data:updated="userCalendar?.refreshCalendarData()"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup lang="ts">
import { useAuth, useDataTableUtilities, usePermissions } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ShiftCalendar from '../ShiftCalendar.vue';

const { t } = useI18n();

const userCalendar = ref<typeof ShiftCalendar>();
const spouseCalendar = ref<typeof ShiftCalendar>();

const { showSnackMessage } = useErrorStore();
const { authUser } = useAuth();
const { spouseCan } = usePermissions();
const { appendIncludes } = useDataTableUtilities();

const tab = ref('mycalendar');
const user = ref<App.Models.User>();

const breadcrumbs = [{ title: t('calendar.mine') }];

const includes = ['unwantedLocations', 'spouse', 'spouse.spouse', 'spouse.unwantedLocations', 'profile_image_status'];

async function loadData() {
  const url = `/api/users/${authUser?.id}`;
  const realUrl = appendIncludes(url, includes);
  try {
    const response = await axios.get(realUrl);
    user.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('errors.cannot_load'));
  }
}

loadData();
</script>

<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <loading-spinner
      v-if="(loadingData > 0) || !courseSession"
    />
    <div v-else>
      <v-card class="mb-3">
        <v-card-text>
          <h2 class="text-primary">
            {{ courseSession?.course?.name }}
          </h2>
          {{ toLongDateWithDay(courseSession.date) }} <br>
          {{ toShortTime(courseSession.begins) }} - {{ toShortTime(courseSession.ends) }}
        </v-card-text>
      </v-card>

      <v-card
        :title="t('location.title')"
        class="mb-3"
      >
        <v-card-text>
          <div
            class="tiptap"
            v-html="courseSession.location"
          />
        </v-card-text>
      </v-card>

      <v-card
        :title="t('global.details')"
        class="mb-3"
      >
        <v-card-text>
          <div
            class="tiptap"
            v-html="courseSession?.course?.description"
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import axios from 'axios';
import { nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const { toLongDateWithDay, toShortTime } = useDate();

const breadcrumbs: Array<BreadCrumb> = [
  {
    title: t('course.training_details'),
  },
];

const courseSession = ref<App.Models.CourseSession>();
const loadingData = ref(0);
const route = useRoute();
const courseSessionId = route.params.id;

function loadCourseSessionData() {
  loadingData.value += 1;
  axios.get(`/api/coursesessions/${courseSessionId}?inc[]=course`).then(
    (response) => {
      courseSession.value = response.data.data;
      loadingData.value -= 1;
    },
    () => {
      loadingData.value -= 1;
    },
  );
}

onMounted(() => {
  nextTick(() => {
    loadCourseSessionData();
  });
});
</script>

<template>
  <v-list-group
    value="publisher"
    class="ms-n12"
  >
    <template #activator="{ props }">
      <v-list-item
        min-height="35px"
        v-bind="props"
        :title="t('smpw.publisher', 2)"
      >
        <template #prepend>
          <v-icon
            class="me-n4"
            icon="fas fa-users"
            size="x-small"
          />
        </template>
      </v-list-item>
    </template>
    <v-list-subheader
      class="ms-n6 text-body-1 font-weight-bold"
      color="white"
      :title="t('help.getting_started')"
    />
    <menu-item
      class="ms-n12"
      :title="t('global.welcome')"
      to="/docs/publisher"
    />
    <menu-item
      class="ms-n12"
      to="/docs/publisher/starting/my-details"
      :title="t('global.my_details')"
    />
    <v-list-subheader
      class="ms-n6 text-body-1 font-weight-bold"
      color="white"
      :title="t('shift.before_shift')"
    />
    <menu-item
      class="ms-n12"
      to="/docs/publisher/before-shifts/news"
      :title="t('news.title')"
    />
    <menu-item
      v-if="can('handle-tasks') || can('manage-tasks')"
      class="ms-n12"
      to="/docs/publisher/before-shifts/my-tasks"
      :title="t('task.mine')"
    />
    <menu-item
      class="ms-n12"
      to="/docs/publisher/before-shifts/current-carts"
      :title="t('cart.current')"
    />
    <menu-item
      :title="t('dayoverseer.title', 2)"
      class="ms-n12"
      to="/docs/publisher/before-shifts/day-overseers"
    />
    <v-list-subheader
      color="white"
      class="ms-n6 text-body-1 font-weight-bold"
      :title="t('shift.managing')"
    />
    <menu-item
      to="/docs/publisher/managing-shifts/availability"
      :title="t('smpw.submit_availability')"
      class="ms-n12"
    />
    <menu-item
      :title="t('shift.mine')"
      class="ms-n12"
      to="/docs/publisher/managing-shifts/my-shifts"
    />
    <menu-item
      to="/docs/publisher/managing-shifts/calendar"
      :title="t('calendar.mine')"
      class="ms-n12"
    />
    <menu-item
      to="/docs/publisher/managing-shifts/current-needs"
      :title="t('shift.current_needs')"
      class="ms-n12"
    />
    <menu-item
      to="/docs/publisher/managing-shifts/locations"
      :title="t('location.title', 2)"
      class="ms-n12"
    />
    <v-list-subheader
      class="ms-n6 text-body-1 font-weight-bold"
      color="white"
      :title="t('shift.after_shift')"
    />
    <menu-item
      class="ms-n12"
      to="/docs/publisher/after-shifts/experiences"
      :title="t('experience.title', 2)"
    />
    <menu-item
      class="ms-n12"
      to="/docs/publisher/after-shifts/contact-us"
      :title="t('actions.contact_us')"
    />
  </v-list-group>
  <v-list-group
    v-if="authUser.is_admin"
    value="admin"
    class="ms-n12"
  >
    <template #activator="{ props }">
      <v-list-item
        min-height="35px"
        v-bind="props"
        :title="t('global.administration')"
      >
        <template #prepend>
          <v-icon
            class="me-n4"
            icon="fas fa-clipboard-user"
            size="x-small"
          />
        </template>
      </v-list-item>
    </template>
    <v-list-subheader
      class="ms-n6 text-body-1 font-weight-bold"
      color="white"
      :title="t('help.getting_started')"
    />
    <menu-item
      class="ms-n12"
      to="/docs/admin"
      :title="t('global.welcome')"
    />
    <v-list-subheader
      class="ms-n6 text-body-1 font-weight-bold"
      color="white"
      :title="t('admin_menu.ministry_management')"
    />
    <menu-item
      class="ms-n12"
      to="/docs/admin/ministry-management/shift-tags"
      :title="t('shift_tag.title', 2)"
    />
    <menu-item
      class="ms-n12"
      to="/docs/admin/ministry-management/locations"
      :title="t('location.title', 2)"
    />
    <menu-item
      class="ms-n12"
      to="/docs/admin/ministry-management/stores"
      :title="t('store.title', 2)"
    />
    <menu-item
      class="ms-n12"
      to="/docs/admin/ministry-management/publications"
      :title="t('publication.title', 2)"
    />
    <v-list-subheader
      class="ms-n6 text-body-1 font-weight-bold"
      color="white"
      :title="t('admin_menu.site_management')"
    />
    <menu-item
      class="ms-n12"
      to="/docs/admin/site-management/documents"
      :title="t('document.title', 2)"
    />
    <menu-item
      to="/docs/admin/site-management/alerts"
      class="ms-n12"
      :title="t('alert.title', 2)"
    />
    <menu-item
      to="/docs/admin/site-management/videos"
      class="ms-n12"
      :title="t('video.title', 2)"
    />
    <menu-item
      to="/docs/admin/site-management/cancellation-reasons"
      class="ms-n12"
      :title="t('cancellation_reason.title', 2)"
    />
    <menu-item
      to="/docs/admin/site-management/contact-options"
      class="ms-n12"
      :title="t('contact_option.title', 2)"
    />
  </v-list-group>

  <v-list-group
    v-if="authUser.is_admin"
    value="maintenance"
    class="ms-n12"
  >
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        min-height="35px"
        :title="t('maintenance.title')"
      >
        <template #prepend>
          <v-icon
            class="me-n4"
            icon="fas fa-cog"
            size="x-small"
          />
        </template>
      </v-list-item>
    </template>

    <menu-item
      class="ms-n12"
      :title="t('help.empty')"
    />
  </v-list-group>
  <v-list-item
    to="/docs/glossary"
    class="ms-n12"
    min-height="35px"
    prepend-icon="fas fa-book"
    :title="t('glossary.title')"
  />
</template>

<script setup lang="ts">
import { useAuth, usePermissions } from '@/composables';
import { useI18n } from 'vue-i18n';
import MenuItem from './MenuItem.vue';

const { authUser } = useAuth();
const { can } = usePermissions();

const { t } = useI18n();
</script>

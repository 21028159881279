<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-if="mode == 'create'"
        color="primary"
        size="large"
        v-bind="activatorProps"
        :text="t('task.new')"
      />
      <v-btn
        v-if="mode == 'edit'"
        class="ml-2"
        color="primary"
        v-bind="activatorProps"
      >
        <v-icon
          icon="fas fa-edit"
        />
      </v-btn>
    </template>
    <task-comment-edition-form
      :model-id="props.modelId"
      :task="props.task"
      :mode="mode"
      @close="close"
      @model:created="reloadAndClose"
      @model:updated="reloadAndClose"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import TaskCommentEditionForm from '@/components/tasks/TaskCommentEditionForm.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelId?: number;
  task: App.Models.Task;
  mode: 'edit' | 'create' | 'show';
}>();

const emit = defineEmits(['reload']);

const { t } = useI18n();

const dialog = ref(false);

function close() {
  dialog.value = false;
}

function reloadAndClose() {
  emit('reload');
  close();
}
</script>

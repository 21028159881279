import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMenuStore = defineStore('menu', () => {
  const unreadNewsCount = ref(0);
  const unacknowledgedShiftsCount = ref(0);
  const newAssignedTasksCount = ref(0);
  const teamCount = ref(0);
  return {
    unreadNewsCount,
    unacknowledgedShiftsCount,
    newAssignedTasksCount,
    teamCount,
  };
});

<template>
  <date-picker
    v-model="selectedDate"
    :min="min"
    :max="max"
    :clearable="false"
    :disabled="disableDateInput"
  >
    <template #text-append>
      <v-btn-group
        elevation="2"
        color="primary"
      >
        <v-btn
          v-if="weekView && showWeekView"
          icon="fas fa-angle-double-left"
          min-width="30"
          @click.stop="onSelectPreviousWeek"
        />
        <v-btn
          v-if="(!weekView || !showWeekView) && selectedDate > min"
          min-width="30"
          icon="fas fa-chevron-left"
          @click.stop="onSelectPreviousDay"
        />

        <v-btn
          v-if="weekView && showWeekView"
          min-width="30"
          icon="fas fa-angle-double-right"
          @click.stop="onSelectNextWeek"
        />
        <v-btn
          v-if="(!weekView || !showWeekView) && selectedDate < max"
          min-width="30"
          icon="fas fa-chevron-right"
          @click.stop="onSelectNextDay"
        />
        <v-btn
          v-if="!readOnly && showWeekView"
          :active="weekView"
          variant="outlined"
          :text="t('date.week')"
          @click.stop="weekView = true"
        />
        <v-btn
          v-if="!readOnly && showWeekView"
          :active="!weekView"
          variant="outlined"
          :text="t('date.day')"
          @click.stop="weekView = false"
        />
      </v-btn-group>
    </template>
  </date-picker>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

withDefaults(defineProps<{
  showWeekView?: boolean;
  min?: string | Date;
  max?: string | Date;
  disableDateInput?: boolean;
}>(), {
  showWeekView: () => true,
  min: () => '2020-01-01',
  max: () => '2030-01-01',
  disableDateInput: () => false,
});
const { t } = useI18n();
const scheduleStore = useScheduleStore();
const {
  selectedDate,
  weekView,
  readOnly,
} = storeToRefs(scheduleStore);

const { getAsDate, toUniversalDate } = useDate();

function onSelectPreviousWeek() {
  const nextDay = getAsDate(selectedDate.value).getTime() - (86400000 * 7);
  selectedDate.value = toUniversalDate(nextDay);
}
function onSelectPreviousDay() {
  const nextDay = getAsDate(selectedDate.value).getTime() - 86400000;
  selectedDate.value = toUniversalDate(nextDay);
}
function onSelectNextWeek() {
  const nextDay = getAsDate(selectedDate.value).getTime() + (86400000 * 7);
  selectedDate.value = toUniversalDate(nextDay);
}
function onSelectNextDay() {
  const nextDay = getAsDate(selectedDate.value).getTime() + 86400000;
  selectedDate.value = toUniversalDate(nextDay);
}
</script>

import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { usePermissions } from '@/composables/usePermissions';

const { can, canAny } = usePermissions();

export async function permissionGuard(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const permission = to.meta?.permission;

  if (!permission) {
    return next();
  }

  if (Array.isArray(permission)) {
    if (canAny(permission))
      return next();
  }

  if (can(permission))
    return next();

  return next({ path: 'unauthorized' });
}

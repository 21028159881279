<template>
  <google-map
    ref="mapRef"
    :api-key="mapsKey"
    :center="center"
    :street-view-control="streetViewControl"
    :rotate-control="rotateControl"
    :zoom-control="zoomControl"
    :disable-default-ui="disabledDefaultUi"
    :scale-control="scaleControl"
    :fullscreen-control="fullscreenControl"
    :map-type-control="mapTypeControl"
    :zoom="realZoom"
    map-id="DEMO_MAP_ID"
    map-type-id="roadmap"
    :style="style"
    @center_changed="onCenterChanged"
  >
    <slot />
  </google-map>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { GoogleMap } from 'vue3-google-map';

const props = withDefaults(defineProps<{
  // center: google.maps.LatLngLiteral;
  center: any;
  zoom?: number;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  zoomControl?: boolean;
  disabledDefaultUi?: boolean;
  scaleControl?: boolean;
  fullscreenControl?: boolean;
  mapTypeControl?: boolean;
  style?: string;
}>(), {
  zoom: () => 15,
  streetViewControl: () => false,
  rotateControl: () => false,
  zoomControl: () => true,
  disabledDefaultUi: () => false,
  scaleControl: () => false,
  fullscreenControl: () => true,
  mapTypeControl: () => false,
  style: () => 'width: 100%; height: 400px',
});

const emit = defineEmits(['center:changed']);

const mapRef = ref();
const realZoom = ref(props.zoom);
const mapsKey = window.MAPS_API_KEY;

function onCenterChanged() {
  emit('center:changed', {
    lat: mapRef.value.map.getCenter().lat(),
    lng: mapRef.value.map.getCenter().lng(),
  });
}
defineExpose({
  mapRef,
});
</script>

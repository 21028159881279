<template>
  <loading-spinner
    v-if="!tasks"
    color="success"
  />
  <div v-else>
    <div class="mb-3">
      <task-edition-dialog
        v-if="xs"
        mode="create"
        size="large"

        @reload="loadTasks"
      />
    </div>
    <div class="d-flex">
      <v-text-field
        v-model="search"
        name="search"
        :label="t('global.search')"
        class="me-2 mb-1"
        clearable
        @change="loadTasks"
      />
      <v-select
        v-model="selectedFilters"
        :items="availableFilters"
        return-object
        item-value="id"
        item-title="name"
        multiple
        chips
        clearable
        class="mb-2"
        :placeholder="t('task.select_filters')"
        @update:model-value="loadTasks"
      >
        <template #item="{ props, item }">
          <v-list-item
            v-bind="props"
            :subtitle="item.raw.description"
          />
        </template>
        <template
          v-if="!xs"
          #append
        >
          <task-edition-dialog
            mode="create"
            size="large"
            @reload="loadTasks"
          />
        </template>
      </v-select>
    </div>
    <div v-if="tasks.length > 0">
      <task-card
        v-for="task in tasks"
        :key="task.id"
        mode="index"
        :task="task"
        @reload="loadTasks"
      />
    </div>

    <div
      v-if="tasks.length > 0"
      class="overflow-auto pt-2"
    >
      <v-pagination
        v-model="currentPage"
        :total-rows="totalTaskCount"
        :length="totalPages"
        :per-page="tasksPerPage"
        :prev-text="t('global.previous')"
        :next-text="t('global.next')"
        @update:model-value="loadTasks"
      />
    </div>
    <v-alert
      v-else
      color="secondary"
      :text="t('task.none')"
    />
  </div>
</template>

<script setup lang="ts">
import {
  useAuth,
  useDataTableUtilities,
  useDate,
} from '@/composables';
import { useErrorStore } from '@/stores/errors.ts';
import axios from 'axios';
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import TaskCard from './TaskCard.vue';
import TaskEditionDialog from './TaskEditionDialog.vue';

const { xs } = useDisplay();
const {
  getDataTableUrl,
} = useDataTableUtilities();

const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const { unixFilter, currentUnix } = useDate();
const { authUser } = useAuth();
const tasks = ref<Array<App.Models.Task>>([]);
const taskCategories = ref<Array<App.Models.TaskCategory>>([]);
const activeStores = ref<Array<App.Models.Store>>([]);
const totalPages = ref(1);
const tasksPerPage = ref(10);
const totalTaskCount = ref(0);
const currentPage = ref(1);
const selectedFilters = ref<any>([]);
const search = ref('');

const availableFilters = computed(() => {
  const filters = [
    {
      id: 1,
      name: t('task.filters.assigned_to_me'),
      description: t('task.filters.assigned_to_me_description'),
      filter: {
        column: 'assignee_id',
        operator: '=',
        value: authUser.id,
      },
    },
    {
      id: 2,
      name: t('states.assigned'),
      description: t('task.filters.assigned_description'),
      filter: {
        column: 'assignee_id',
        operator: '<>',
        value: null,
      },
    },
    {
      id: 3,
      name: t('states.unassigned'),
      description: t('task.filters.unassigned_description'),
      filter: {
        column: 'assignee_id',
        operator: '=',
        value: null,
      },
    },
    {
      id: 4,
      name: t('states.open'),
      description: t('task.filters.open_description'),
      filter: {
        column: 'status',
        operator: '<>',
        value: 'completed',
      },
    },
    {
      id: 5,
      name: t('states.completed'),
      description: t('task.filters.completed_description'),
      filter: {
        column: 'status',
        operator: '=',
        value: 'completed',
      },
    },
  ];
  if (taskCategories.value) {
    taskCategories.value.forEach((category) => {
      filters.push({
        id: (filters.length + 1),
        name: category.name,
        description: t('task.filters.category_description', { category: category.name }),
        filter: {
          column: 'task_category_id',
          operator: '=',
          value: category.id,
        },
      });
    });
  }
  if (activeStores.value) {
    activeStores.value.forEach((store) => {
      filters.push({
        id: (filters.length + 1),
        name: store.name,
        description: t('task.filters.store_description', { store: store.name }),
        filter: {
          column: 'store_id',
          operator: '=',
          value: store.id,
        },
      });
    });
  }
  return filters;
});
/* @ts-ignore */
const activeFilters = computed<any>(() => {
  if (selectedFilters.value) {
    return selectedFilters.value.map((f) => f.filter);
  }
  return [];
});

function saveSelectedFilters() {
  if (selectedFilters.value) {
    const filters = selectedFilters.value;
    localStorage.setItem('TaskFilters', JSON.stringify({
      timestamp: currentUnix(),
      filters,
    }));
  }
}

async function loadTasks() {
  const url = getDataTableUrl(
    '/api/tasks',
    ['store', 'reporter', 'assignee', 'assignee.profileImage', 'comments'],
    tasksPerPage.value,
    currentPage.value,
    search.value,
    [],
    null,
    null,
    activeFilters.value,
  );

  saveSelectedFilters();
  try {
    const response = await axios.get(url);
    tasks.value = response.data.data;
    // Updating pagination data
    totalTaskCount.value = response.data.total;
    totalPages.value = response.data.last_page;
  }
  catch (error) {
    showSnackMessage(t('task.cannot_load'));
  }
}

function loadTaskCategories() {
  axios.get('/api/taskcategories')
    .then((response) => {
      taskCategories.value = response.data.data;
    });
}
function loadActiveStores() {
  axios.get('/api/stores?active=true')
    .then((response) => {
      activeStores.value = response.data.data;
    });
}
function loadSelectedFilters() {
  try {
    const storageData = localStorage.getItem('TaskFilters');
    if (storageData) {
      const filterData = JSON.parse(storageData);
      if (filterData.timestamp && unixFilter(filterData.timestamp)) {
        return filterData.filters;
      }
    }
    return availableFilters.value.filter((f) => [1, 4].includes(f.id));
  }
  catch {
    return availableFilters.value.filter((f) => [1, 4].includes(f.id));
  }
}

onMounted(() => {
  if (!selectedFilters.value) {
    selectedFilters.value = loadSelectedFilters();
  }

  loadTasks();
  loadTaskCategories();
  loadActiveStores();
});
</script>

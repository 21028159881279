<template>
  <v-avatar
    :style="style"
    :size="size"
    :class="user.gender == 'b' ? 'user_card_pic_border_b' : 'user_card_pic_border_s'"
    class="user_card_pic_border my-1 mx-1"
  >
    <v-img
      v-if="tooltip"
      v-tooltip="tooltip"
      :src="user.profile_image_url"
    />
    <v-img
      v-else
      :src="user.profile_image_url"
    />
  </v-avatar>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps < {
  user: Partial<App.Models.User | App.ApiResources.PublisherWithShiftDetailsResource>;
  size?: string;
  tooltip?: string;
  new?: boolean;
  trainer?: boolean;
  notAcknowleged?: boolean;
  captain?: boolean;
}>(), {
  size: () => '150',
  new: () => false,
  captain: () => false,
  trainer: () => false,
  notAcknowleged: () => false,
});

const style = computed(() => {
  if (props.new) {
    return 'box-shadow: yellow 0px 0px 20px 5px !important;';
  }
  if (props.trainer) {
    return 'box-shadow: green 0px 0px 20px 5px !important;';
  }
  if (props.captain) {
    return 'box-shadow: #4d89db 0px 0px 20px 5px !important;';
  }
  return '';
});
</script>

<style scoped>
.user_card_pic_border {
    border-width: 3px;
    border-style: solid;
    border-color: white;
}
.user_card_pic_border:hover {
    filter: brightness(110%);
}
.user_card_pic_border_b {
  outline: 3px solid #4d89db;
}
.user_card_pic_border_s {
  outline: 3px solid #F240AE;
}
</style>

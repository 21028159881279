<template>
  <div
    v-if="store"
    class="mb-4"
  >
    <smpw-map
      :center="mapCenter"
    >
      <smpw-map-marker
        :title="store.name"
        :position="mapCenter"
        marker-image-url="/images/mapmarkers/pin_store.png"
        :read-only="readOnly"
        @update:position="updateLocation"
      />
    </smpw-map>

    <div
      v-if="!readOnly"
      class="mt-5"
    >
      <v-text-field
        v-model="store.lat"
        :label="t('location.latitude')"
        disabled
      />
      <v-text-field
        v-model="store.lng"
        :label="t('location.longitude')"
        disabled
      />
    </div>
    <localizer-dialog
      v-if="!readOnly"
      :button-text="t('location.search_move')"
      marker-image-url="/images/mapmarkers/pin_store.png"
      @update:location="updateLocationFromLocalizer"
    />
  </div>
</template>

<script setup lang="ts">
import SmpwMap from '@/components/globals/SmpwMap.vue';
import SmpwMapMarker from '@/components/globals/SmpwMapMarker.vue';
import { useConfig } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LocalizerDialog from '../dialogs/LocalizerDialog.vue';

withDefaults(defineProps<{
  readOnly?: boolean;
}>(), {
  readOnly: () => false,
});

const { t } = useI18n();
const { getConfig } = useConfig();

const store = defineModel<any>();

function updateLocationFromLocalizer(e) {
  store.value.lat = e.lat;
  store.value.lng = e.lng;
}
function updateLocation(e) {
  store.value.lat = e.latLng.lat();
  store.value.lng = e.latLng.lng();
}

const mapCenter = computed(() => ({
  lng: store.value.lng || getConfig('smpw.city_centre_lng'),
  lat: store.value.lat || getConfig('smpw.city_centre_lat'),
}));
</script>

<template>
  <v-snackbar
    v-model="snackVisible"
    timer="2000"
    :text="snackMessage"
    :color="snackType"
  />
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors.ts';
import { toRefs } from 'vue';

const store = useErrorStore();
const { snackMessage, snackType, snackVisible } = toRefs(store);
</script>
@/stores/errors

import type { IntlDateTimeFormats } from 'vue-i18n';
import { useAuth } from '@/composables/useAuth';
import messages from '@intlify/unplugin-vue-i18n/messages';
import moment from 'moment';
import { createI18n } from 'vue-i18n';
import * as vuetifyLocales from 'vuetify/locale';

const { authUser } = useAuth();
const permittedLocales: string[] = ['en', 'fr', 'es', 'pt'] as const;
export type PermittedLocale = typeof permittedLocales[number];
const defaultLanguage: PermittedLocale = 'en';
const overrideLanguageForTesting: PermittedLocale | undefined = undefined;

export const availableLanguages: { locale: PermittedLocale; name: string }[] = [
  { locale: 'en', name: 'English' },
  { locale: 'fr', name: 'Français' },
  { locale: 'es', name: 'Español' },
//   { locale: 'pt', name: 'Português' },
];

const datetimeFormats: IntlDateTimeFormats = {
  en: {
    short: {
      day: 'numeric',
      month: 'short',
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    short_day: {
      weekday: 'short',
    },
    short_with_day: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    },
    long_day: {
      weekday: 'long',
    },
    long_date_with_day: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_short_day: {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_time: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    long_date_with_day_and_time: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  fr: {
    short: {
      day: 'numeric',
      month: 'short',
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    short_day: {
      weekday: 'short',
    },
    short_with_day: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    },
    long_day: {
      weekday: 'long',
    },
    long_date_with_day: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_short_day: {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_time: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    long_date_with_day_and_time: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  es: {
    short: {
      day: 'numeric',
      month: 'short',
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    short_day: {
      weekday: 'short',
    },
    short_with_day: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    },
    long_day: {
      weekday: 'long',
    },
    long_date_with_day: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_short_day: {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_time: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    long_date_with_day_and_time: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  pt: {
    short: {
      day: 'numeric',
      month: 'short',
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    short_with_day: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    },
    short_day: {
      weekday: 'short',
    },
    long_day: {
      weekday: 'long',
    },
    long_date_with_day: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_short_day: {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    long_date_with_time: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    long_date_with_day_and_time: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

const messagesWithVuetify = {};

if (messages) {
  Object.keys(messages).forEach((key) => {
    const vuetifyLocale = vuetifyLocales[key];
    if (messages[key] && vuetifyLocale) {
      messagesWithVuetify[key] = {
        ...messages[key],
        $vuetify: {
          ...vuetifyLocale,
          ...messages[key].$vuetify as typeof vuetifyLocale,
        },
      };
    }
  });
}

function isLocalePermitted(locale: string): boolean {
  return permittedLocales.includes(locale);
}

function getPreferredLocaleOrUseBrowserSetting(): PermittedLocale {
  if (overrideLanguageForTesting) {
    return overrideLanguageForTesting;
  }

  if (authUser?.preferred_language && isLocalePermitted(authUser?.preferred_language)) {
    return authUser.preferred_language;
  }

  if (navigator?.languages.length && isLocalePermitted(navigator.languages[0])) {
    const [locale] = navigator.languages;
    return locale;
  }

  if (Object.keys(navigator).includes('userLanguage')) {
    const { userLanguage } = navigator as unknown as { userLanguage: string };
    if (isLocalePermitted(userLanguage)) {
      return userLanguage;
    }
  }

  if (navigator.language && isLocalePermitted(navigator.language)) {
    return navigator.language;
  }

  if (Object.keys(navigator).includes('browserLanguage')) {
    const { browserLanguage } = navigator as unknown as { browserLanguage: string };
    if (isLocalePermitted(browserLanguage)) {
      return browserLanguage;
    }
  }

  return defaultLanguage;
}

export async function setMomentLocale(locale: string): Promise<void> {
  try {
    if (locale !== 'en') {
      await import(`moment/dist/locale/${locale}.js`);
    }
    moment.locale(locale);
  }
  catch (e) {
    moment.locale('en');
    console.warn(`Cannot load moment locale ${locale}`, e);
  }
}

export function initI18n() {
  const locale = getPreferredLocaleOrUseBrowserSetting();
  setMomentLocale(locale);
  return createI18n({
    legacy: false,
    locale,
    fallbackLocale: defaultLanguage,
    messages: messagesWithVuetify,
    datetimeFormats,
  });
}

<template>
  <div>
    <v-autocomplete
      v-if="autocomplete"
      v-model="selectedModelId"
      :name="name"
      :label="label"
      :clear-on-select="clearOnSelect"
      :multiple="multiple"
      :disabled="isDisabled"
      :error="error"
      :error-messages="errorMessages"
      :items="filteredModels"
      :item-title="labelFieldName"
      :item-value="idFieldName"
      :loading="loadingCount > 0"
      clearable
      :hint="hint"
      :persistent-hint="persistentHint"
      :return-object="returnObject"
      :hide-details="hideDetails"
      @update:model-value="setSelectedModel"
    >
      <template #item="{ props }">
        <v-list-item v-bind="props" />
      </template>
    </v-autocomplete>

    <v-select
      v-else
      v-model="selectedModelId"
      :items="filteredModels"
      :item-title="labelFieldName"
      :item-value="idFieldName"
      :name="name"
      :label="label"
      :multiple="multiple"
      :disabled="isDisabled"
      :error="error"
      :error-messages="errorMessages"
      clearable
      :hint="hint"
      :persistent-hint="persistentHint"
      :return-object="returnObject"
      :hide-details="hideDetails"
      @change="setSelectedModel"
    />

    <v-alert
      v-model="errorShown"
      closable
      icon="error"
      type="error"
      :text="errorMessage"
      @click:close="loadAllData"
    />
  </div>
</template>

<script setup lang="ts">
import { useDataTableUtilities } from '@/composables/index.ts';
import axios from 'axios';
import {
  computed,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

const properties = withDefaults(defineProps<{
  name?: string;
  multiple?: boolean;
  disabled?: boolean;
  clearOnSelect?: boolean;
  loadAll?: boolean;
  indexRoute: string;
  labelFieldName?: string;
  idFieldName?: string;
  searchFields?: Array<string>;
  includes?: Array<string>;
  filters?: Array<SearchFilter>;
  sortFieldName?: string;
  state?: boolean;
  recordLimit?: number;
  error?: boolean;
  errorMessages?: Array<string>;
  label?: string;
  perPage?: number;
  fields?: Array<string>;
  hint?: string;
  persistentHint?: boolean;
  returnObject?: boolean;
  hideDetails?: boolean;
  indexRouteParameters?: any;
  autocomplete?: boolean;
}>(), {
  multiple: () => false,
  perPage: () => 50,
  disabled: () => false,
  loadAll: () => false,
  includes: () => [],
  clearOnSelect: () => false,
  labelFieldName: () => 'name',
  idFieldName: () => 'id',
  sortFieldName: () => 'name',
  error: () => false,
  errorMessages: () => [],
  fields: () => [],
  filters: () => [],
  persistentHint: () => true,
  returnObject: () => false,
  hideDetails: () => false,
  autocomplete: () => true,
});

const { t } = useI18n();

const { appendFilters } = useDataTableUtilities();
const loadingCount = ref(0);
const selectedModelId = defineModel<any>();
const selectedModel = defineModel<any>('selectedModel');
const loadedModels = defineModel<Array<App.Models.BaseModel>>('loadedModels', { default: [] });
const searchString = ref('');
const errorShown = ref(false);

const isDisabled = computed(() => properties.disabled || loadingCount.value > 0);
const filteredModels = computed<App.Models.BaseModel[]>(() => loadedModels.value.filter((el) => el[properties.labelFieldName].includes(searchString.value)));
const errorMessage = ref<any>(null);

function setSelectedModel() {
  selectedModel.value = (loadedModels.value ? loadedModels.value.find((model) => model.id === selectedModelId.value) : null);
}

async function loadAllData() {
  loadedModels.value = [];

  // Load Selected Model First.
  if (selectedModelId.value) {
    const tempFilters = [
      { column: properties.idFieldName, value: selectedModelId.value, operator: '=' },
    ];
    const response = await axios.get(appendFilters(properties.indexRoute, tempFilters));
    loadedModels.value = loadedModels.value.concat(response.data.data);
  }

  let currentPage = 0;
  let lastPage = 1;
  while (currentPage < lastPage) {
    currentPage++;
    loadingCount.value += 1;

    const queryData = {
      page: currentPage,
      per_page: properties.perPage,
      fields: properties.fields.join(','),
      inc: properties.includes,
      sort: properties.sortFieldName,
      ...properties.indexRouteParameters,
    };

    try {
      const response = await axios.get(appendFilters(properties.indexRoute, properties.filters), { params: queryData });
      loadingCount.value -= 1;
      loadedModels.value = loadedModels.value.concat(response.data.data);
      lastPage = response.data.last_page;
    }
    catch (error) {
      loadingCount.value -= 1;
      errorMessage.value = t('errors.cannot_load_try_again');
      errorShown.value = true;
      console.error(error);
      break;
    }
  }
  setSelectedModel();
}

loadAllData();
</script>

<template>
  <div class="mb-1">
    <v-row class="mx-1">
      <v-dialog
        v-model="showSlideshow"
        :fullscreen="xs"
        width="600px"
        :height="xs ? '' : '700px'"
      >
        <global-card
          :title="t('images.title', 2)"
          closable
          @close="showSlideshow = false"
        >
          <image-slideshow
            v-if="readOnly"
            :id="`${name}-slide-show`"
            v-model="slideShowIndex"
            :images="slideShowImages"
            @close="showSlideshow = false"
          />
          <template #actions>
            <v-btn
              color="dark-grey"
              :text="t('actions.close')"
              @click="showSlideshow = false"
            />
          </template>
        </global-card>
      </v-dialog>
      <div
        v-for="(image, imageIndex) in images.filter((image) => !image.deleted)"
        :key="imageIndex"
      >
        <v-row class="mt-1">
          <v-col class="d-flex flex-column mx-2">
            <div
              class="image mb-2"
              :style="{
                border: getBorder(image),
                backgroundImage: `url(${getHref(image)})`,
                width: thumbnailWidth,
                height: thumbnailHeight
              }"
              @click.self="onImageClick(imageIndex, image)"
            >
              <div class="d-flex flex-row justify-space-between">
                <v-checkbox
                  v-if="selectable"
                  v-model="image.selected"
                  class="ma-2"
                />
                <v-btn
                  v-if="!readOnly"
                  v-model="image.selected"
                  icon="fas fa-trash"
                  color="error"
                  class="ma-2"
                  @click="image.deleted = true"
                />
              </div>
            </div>
            <div class="mb-6">
              <v-text-field
                v-if="!readOnly"
                v-model="image.title"
                :placeholder="t('global.title')"
                hide-details
              />
              <p
                v-if="readOnly"
                class="text-font"
                style="word-wrap:break-word; width: 300px"
              >
                {{ image.title }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <image-upload-button
      v-if="!readOnly"
      class="mt-4"
      :caption="t('images.upload')"
      :on-upload-image="onUploadImage"
      :on-upload-image-guard="onUploadImageGuard"
      :multiple-images="!isSingleImage"
      :error="error"
      :error-messages="errorMessages"
    />
    <v-alert
      v-if="(images.length === 0) && readOnly"
      class="text-font"
      :text="t('images.none_available')"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import ImageSlideshow from './ImageSlideshow.vue';
import ImageUploadButton from './ImageUploadButton.vue';
import { useImageValidation } from './useImageValidation';

const props = withDefaults(defineProps<{
  name?: string;
  readOnly?: boolean;
  enableSlideShow?: boolean;
  selectable?: boolean;
  maxImages?: number;
  squareImage?: boolean;
  maxImageKilobyteSize?: number;
  minImageDimSize?: number;
  minWidthImageDimSize?: number;
  minHeightImageDimSize?: number;
  errorMessages?: any;
  error?: boolean;
}>(), {
  maxImages: () => 0,
  squareImage: () => false,
  maxImageKilobyteSize: () => 10240,
});
const { t } = useI18n();
const { validateImage } = useImageValidation();

const { xs } = useDisplay();

const images = defineModel<any>();
const slideShowIndex = ref<number | null>(null);
const showSlideshow = ref(false);
const thumbnailWidth = '300px';

const thumbnailHeight = computed(() => {
  if (props.squareImage) {
    return '300px';
  }
  return '200px';
});

function getHref(image) {
  return image.base_64_image ? image.base_64_image : image.signed_file_url;
}

const slideShowImages = computed(() => images.value.filter((image) => !image.deleted).map((image) => ({
  title: image.title,
  href: getHref(image),
})));

const isSingleImage = computed(() => props.maxImages === 1);

function onImageClick(imageIndex, image) {
  if (props.enableSlideShow) {
    slideShowIndex.value = imageIndex;
    showSlideshow.value = true;
  }
  else if (props.selectable) {
    image.selected = !image.selected;
  }
}

function getBorder(image) {
  if (props.selectable && image.selected) {
    return '2px solid #02979d';
  }
  return '';
}

function onUploadImage(uploadedImages) {
  let lowestId = -1;
  if (images.value.length > 0) {
    lowestId = Math.min.apply(null, images.value.map((image) => image.id));
    lowestId = lowestId > -1 ? -1 : lowestId - 1;
  }
  uploadedImages.forEach((newImage) => {
    images.value.push({
      id: lowestId,
      title: newImage.title,
      base_64_image: newImage.data,
      selected: false,
      deleted: false,
    });
    lowestId -= 1;
  });
  if (props.maxImages > 0) {
    while (images.value.filter((image) => !image.deleted).length > props.maxImages) {
      images.value.filter((image) => !image.deleted).find(() => true).deleted = true;
    }
  }
}
function onUploadImageGuard(image) {
  return validateImage(
    image,
    props.squareImage,
    props.maxImageKilobyteSize,
    props.minImageDimSize,
    props.minWidthImageDimSize,
    props.minHeightImageDimSize,
  );
}
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
}
</style>

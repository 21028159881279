<template>
  <v-dialog
    v-model="dialog"
    :width="!xs ? 'auto' : undefined"
    :fullscreen="xs"
  >
    <template #activator="{ props: activatorProps }">
      <v-btn
        color="secondary"
        size="large"
        prepend-icon="fas fa-edit"
        v-bind="activatorProps"
        :text="t('team.edit')"
      />
    </template>
    <team-edition-form
      :model-id="props.modelId"
      :mode="props.mode"
      @close="close"
      @model:created="reloadAndClose"
      @model:updated="reloadAndClose"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import TeamEditionForm from '@/components/teams/TeamEditionForm.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const props = defineProps<{
  modelId?: number;
  mode: 'edit' | 'create' | 'show';
}>();
const emit = defineEmits(['reload']);
const { xs } = useDisplay();
const { t } = useI18n();

const dialog = ref(false);

function close() {
  dialog.value = false;
}

function reloadAndClose() {
  emit('reload');
  close();
}
</script>

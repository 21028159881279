<template>
  <login-box>
    <v-alert
      class="mb-5"
      :text="t('login.activation_title')"
    />
    <v-form method="POST">
      <csrf-token />
      <v-text-field
        :label="t('global.username')"
        name="username"
        types="text"
        autofocus
      />

      <password-input
        name="password"
        :label="t('password.title')"
      />

      <password-input
        name="password_confirmation"
        :label="t('password.confirmation')"
      />

      <v-btn
        type="submit"
        color="primary"
        :text="t('login.activate_account')"
      />
    </v-form>
  </login-box>
</template>

<script setup lang="ts">
import CsrfToken from '@/components/CsrfToken.vue';
import { useI18n } from 'vue-i18n';
import LoginBox from './LoginBox.vue';
import PasswordInput from './PasswordInput.vue';

const { t } = useI18n();
</script>

import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

export function initAxios() {
  window.axios = axios;
  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const pendingRequests: { [key: string]: AbortController } = {};

  const removePendingRequest = (url: string | undefined, abort = false): void => {
    // check if pendingRequests contains our request URL
    if (url && pendingRequests[url]) {
      // if we want to abort ongoing call, abort it
      if (abort) {
        pendingRequests[url].abort();
      }
      // remove the request URL from pending requests
      delete pendingRequests[url];
    }
  };

  axios.interceptors.request.use((config) => {
    // we only want to cancel requests if config.cancelPreviousRequests is true
    // if the config.url exists
    // if the config doesn't contain AbortController.signal already, in this case we let the developer handle it himself

    const endpointsToCancel = [
      '/api/shifts/schedule/day',
      '/api/shifts/schedule/week',
    ];
    const endpoint = config?.url?.split('?')[0] ?? 'ignoreme';

    if (endpointsToCancel.includes(endpoint) && !config.signal) {
      // remove request URL from pending requests and also abort ongoing call with same URL
      removePendingRequest(endpoint, true);

      const abortController = new AbortController(); // create new AbortController
      config.signal = abortController.signal; // assign it's signal into request config
      pendingRequests[endpoint] = abortController; // store AbortController in the pending requests map
    }

    return config;
  });

  /**
   * Next we will register the CSRF Token as a common header with Axios so that
   * all outgoing HTTP requests automatically have it attached. This is just
   * a simple convenience so we don't have to attach every token manually.
   */
  const token = document.head.querySelector('meta[name="csrf-token"]');
  if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = (token as HTMLMetaElement).content;
  }
  else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  }

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      // remove pending request without aborting the call, call is finished
      removePendingRequest(response.request.responseURL);
      return response;
    },
    async (error: AxiosError) => {
      if (axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.log('Request canceled:', error.config?.url);
      }

      error = error as any;

      removePendingRequest(error?.config?.url);
      if (
        error?.response?.status === 401 // invalid or missing authentication credentials
        || error?.response?.status === 419 // session expired
      ) {
        window.location.reload();
      }

      if (error?.response?.status === 413) {
        /** @ts-ignore-next */
        error.response.data.message = 'File too large.';
      }
      return Promise.reject(error);
    },
  );
}

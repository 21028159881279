import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';

export function useDataTableUtilities() {
  const appendFilters = (url: string, filters: Array<any>) => {
    let urlWithFilters = url;
    filters.forEach((filter, index) => {
      const filtersColumn = encodeURI(`filters[${index}][column]`);
      const filtersOperator = encodeURI(`filters[${index}][operator]`);
      const filtersValue = encodeURI(`filters[${index}][value]`);
      urlWithFilters += `${urlWithFilters.includes('?') ? '&' : '?'}${filtersColumn}=${encodeURI(filter.column)}`;
      urlWithFilters += `&${filtersOperator}=${encodeURI(filter.operator)}`;
      urlWithFilters += `&${filtersValue}=${encodeURI(filter.value)}`;
    });
    return urlWithFilters;
  };
  const appendRecordLimit = (url: string, recordLimit: string) => {
    let urlWithRecordLimit = url.includes('?') ? `${url}&` : `${url}?`;
    urlWithRecordLimit += `per_page=${encodeURI(recordLimit)}`;
    return urlWithRecordLimit;
  };
  const appendSortColumn = (url: string, sortColumn: string, columns: Array<any>, direction: string | number) => {
    const sortColumnIndex = columns.indexOf(sortColumn);
    if (sortColumnIndex >= 0) {
      return `${url}${url.includes('?') ? '&' : '?'}${encodeURI('order[0][column]')}=${sortColumnIndex}&${encodeURI('order[0][dir]')}=${direction}`;
    }
    return url;
  };
  const appendColumns = (url: string, columns: Array<any>) => {
    let urlWithColumns = url;
    columns.forEach((col, index) => {
      const columnsData = encodeURI(`columns[${index}][data]`);
      urlWithColumns += `${urlWithColumns.includes('?') ? '&' : '?'}${columnsData}=${encodeURI(col)}`;
    });
    return urlWithColumns;
  };
  const appendSearchString = (url: string, searchString: string) => {
    let urlWithSearchString = url.includes('?') ? `${url}&` : `${url}?`;
    urlWithSearchString += `${encodeURI('search')}=${encodeURI(searchString)}`;
    return urlWithSearchString;
  };

  const appendPagination = (url: string, pageSize: string | number, currentPage: string | number) => {
    let urlWithPagination = url.includes('?') ? `${url}&` : `${url}?`;
    urlWithPagination += `per_page=${pageSize}&page=${currentPage}`;
    return urlWithPagination;
  };

  const appendIncludes = (url: string, includes: Array<string>) => {
    let urlWithIncludes = url;
    if (includes.length > 0) {
      includes.forEach((inc) => {
        if (urlWithIncludes.includes('?')) {
          urlWithIncludes += `&inc[]=${encodeURI(inc)}`;
        }
        else {
          urlWithIncludes += `?inc[]=${encodeURI(inc)}`;
        }
      });
    }
    return urlWithIncludes;
  };

  const appendFields = (url: string, includes: Array<string>) => {
    let urlWithIncludes = url;
    if (includes.length > 0) {
      includes.forEach((inc) => {
        if (urlWithIncludes.includes('?')) {
          urlWithIncludes += `&fields[]=${encodeURI(inc)}`;
        }
        else {
          urlWithIncludes += `?fields[]=${encodeURI(inc)}`;
        }
      });
    }
    return urlWithIncludes;
  };

  const formatSort = (url: string, sortValue: Array<any>) => {
    if (sortValue === undefined) {
      return '';
    }
    return `${url.includes('?') ? '&' : '?'}sort=${
      sortValue.map((element, index) => (element.order === 'desc' ? `-${element.key}` : element.key))
        .join(',')}`;
  };

  const getDataTableUrl = (
    route: string,
    includes: Array<string> = [],
    pageSize: number = 25,
    currentPage: number = 1,
    searchString: string | null = null,
    columns: Array<string> = [],
    sort = null,
    recordLimit = null,
    filters: Array<SearchFilter> = [],
    fields = [],
  ) => {
    let url = route;
    const requiredColumns = (columns) || [];
    if (includes) {
      url = appendIncludes(url, includes);
    }
    if (pageSize && currentPage) {
      url = appendPagination(url, pageSize, currentPage);
    }
    if (searchString) {
      url = appendSearchString(url, searchString);
    }
    if (requiredColumns.length > 0) {
      url = appendColumns(url, requiredColumns);
    }
    if (sort) {
      url += formatSort(url, sort);
    }
    if (recordLimit && !(pageSize && currentPage)) {
      url = appendRecordLimit(url, recordLimit);
    }
    if (filters.length) {
      url = appendFilters(url, filters);
    }
    if (fields) {
      url = appendFields(url, fields);
    }
    return url;
  };

  const { showSnackMessage } = useErrorStore();

  const writableAttributes = ref<string[]>();

  async function loadWritableAttributes(baseUrl: string, modelId: string | number | undefined) {
    const requestUrl = modelId ? `${baseUrl}/writable/${modelId}` : `${baseUrl}/writable`;
    try {
      const response = await axios.get(requestUrl);
      writableAttributes.value = response.data.data;
    }
    catch (error) {
      showSnackMessage('Could not load writable routes.');
    }
  }
  const canWriteField = (fieldName) => (!writableAttributes.value || (writableAttributes.value?.includes(fieldName)));
  const canWriteAnyField = (fieldNames) => fieldNames.find((f) => canWriteField(f)) !== undefined;

  return {
    getDataTableUrl,
    appendFilters,
    appendRecordLimit,
    appendColumns,
    appendSortColumn,
    appendSearchString,
    appendPagination,
    appendIncludes,
    appendFields,
    canWriteField,
    canWriteAnyField,
    loadWritableAttributes,
  };
}

export function useTaskUtilities() {
  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'new':
        return 'warning';
      case 'in_progress':
        return 'success';
      case 'waiting':
        return 'error';
      case 'completed':
        return 'gray';
      default:
        return 'primary';
    }
  };
  return {
    getStatusBadgeClass,
  };
}

import { ref } from 'vue';

export function useSettings() {
  const settings = ref(window.settings);

  const getSetting = (sid) => (settings.value?.[sid]) || null;

  return {
    getSetting,
  };
}

<template>
  <v-dialog
    v-if="shiftId"
    v-model="dialog"
    :fullscreen="xs"
    size="x-large"
    max-width="900px"
  >
    <global-card
      :title="t('shift.details')"
      closable
      @close="close"
    >
      <shift-details
        :shift-id="shiftId"
        :with-breadcrumbs="false"
      />
      <template #actions>
        <v-btn
          :text="t('actions.close')"
          color="dark-grey"
          @click="close"
        />
      </template>
    </global-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import ShiftDetails from '../ShiftDetails.vue';

const { xs } = useDisplay();
const { t } = useI18n();

const dialog = ref(false);
const shiftId = ref(null);

function show(shiftID) {
  shiftId.value = shiftID;
  dialog.value = true;
}

function close() {
  dialog.value = false;
}

defineExpose({ show });
</script>

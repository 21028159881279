export function useAuth() {
  const authUser = window.authuser;

  function isLoggedIn() {
    return authUser !== null;
  }

  const logout = () => {
    const logoutForm = document.getElementById('logout-form') as HTMLFormElement;
    logoutForm.submit();
  };

  return {
    authUser,
    isLoggedIn, // watch out, this is not reactive
    logout,
  };
}

<template>
  <div v-if="visible">
    <v-text-field
      v-if="withBar"
      v-model.lazy="search"
      clearable
      :label="t('global.search')"
      @update:model-value="onSearchChange"
      @click:clear="onSearchChange"
    >
      <template #prepend-inner>
        <v-icon
          icon="fas fa-search"
          size="x-small"
        />
      </template>
      <template #append>
        <slot name="append" />
      </template>
    </v-text-field>
    <v-alert
      v-if="(items.length === 0) && (childComponentItems.length > 0)"
      type="error"
    >
      <i18n-t keypath="search.no_results">
        <template #query>
          <strong>"{{ search }}"</strong>
        </template>
      </i18n-t>
    </v-alert>
    <v-alert
      v-if="(childComponentItems.length === 0)"
      type="info"
      :text="t('states.loading')"
    />
    <component
      :is="childComponent"
      v-bind="childComponentProperties"
      :key="componentKey"
      :items="items"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = withDefaults(defineProps<{
  childComponent: object;
  childComponentItems: Array<any>;
  childComponentProperties?: object;
  itemsSearchFields: Array<any>;
  withBar?: boolean;
}>(), {
  withBar: () => true,
});

const { t } = useI18n();

const search = ref('');
const items = ref<any>([]);
const componentKey = ref(0);
const visible = ref(false);

function onSearchChange() {
  items.value = props.childComponentItems.filter((p) => {
    let filtered = false;
    if (!search.value) {
      search.value = '';
    }
    props.itemsSearchFields.forEach((f) => {
      if (p[f].toLowerCase().search(search.value.toLowerCase()) >= 0) {
        filtered = true;
      }
    });
    return filtered;
  });
  componentKey.value += 1;
}

function refresh() {
  onSearchChange();
}

onMounted(() => {
  items.value = props.childComponentItems;
  props.itemsSearchFields.forEach((f) => {
    if (items.value.filter((p) => p[f] === undefined).length > 0) {
      throw new Error(`Each item must contain a '${f}' field to search on.`);
    }
    else {
      visible.value = true;
    }
  });
});

watch(() => props.childComponentItems, () => {
  refresh();
});
</script>

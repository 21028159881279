<template>
  <div>
    <a
      :href="`tel:${number}`"
      :class="className"
      class="text-primary text-lowercase text-decoration-none text-subtitle-1"
    >
      <v-icon
        :icon="icon"
        :size="size"
      />
      {{ number ?? '' }}
    </a>
    <v-btn
      v-tooltip="t('actions.copy')"
      color="primary"
      :class="className"
      size="x-small"
      variant="plain"
      @click="copyToClipboard()"
    >
      <v-icon icon="fas fa-copy" />
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const props = withDefaults(defineProps<{
  number: string;
  icon?: string;
  size?: string;
  className?: string;
}>(), {
  icon: () => 'fas fa-phone',
  size: () => 'small',
  className: () => 'text-subtitle-1',
});

const { t } = useI18n();
function copyToClipboard() {
  navigator.clipboard.writeText(props.number);
}
</script>

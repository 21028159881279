<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <user-details
      v-if="authUser.spouse_id"
      :user-id="authUser.spouse_id"
      :show-extended-info="false"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth } from '@/composables';
import { useI18n } from 'vue-i18n';
import UserDetails from './UserDetails.vue';

const { t } = useI18n();

const { authUser } = useAuth();

const breadcrumbs = [{ title: t('user.spouse_details') }];
</script>

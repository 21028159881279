<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <v-container
      v-if="!task"
      class="mt-5 mb-5"
    >
      <loading-spinner />
    </v-container>
    <div
      v-else
    >
      <task-card
        v-if="task"
        class="mt-2"
        :task="task"
        mode="show"
        @model:deleted="redirect"
      />
      <h4 class="text-h5">
        {{ t('task.comments') }}
      </h4>
      <v-divider class="mb-2" />
      <div>
        <div v-if="task.comments && task.comments.length > 0">
          <task-comment-card
            v-for="comment in task.comments"
            :key="comment.id"
            :comment="comment"
            :task="task"
            @model:deleted="loadTask"
            @reload="loadTask"
          />
        </div>
        <div v-else>
          <v-alert
            class="mb-2"
            color="secondary"
            :text="t('task.no_comments')"
          />
        </div>
      </div>
      <task-comment-edition-form
        mode="create"
        :task="task"
        @model:created="loadTask"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  useDataTableUtilities,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import TaskCard from './TaskCard.vue';
import TaskCommentCard from './TaskCommentCard.vue';
import TaskCommentEditionForm from './TaskCommentEditionForm.vue';

const props = defineProps<{
  id: string | number;
}>();
const { t } = useI18n();
const { showSnackMessage } = useErrorStore();

const {
  appendIncludes,
} = useDataTableUtilities();

const router = useRouter();

const task = ref<App.Models.Task>();
const includes = [
  'reporter',
  'assignee',
  'assignee.profileImage',
  'comments',
  'comments.commenter',
  'comments.commenter.profileImage',
  'store',
  'category',
];

function redirect() {
  router.push({ path: '/tasks' });
}

const breadcrumbs = computed(() => [
  {
    title: t('task.title', 2),
    to: '/tasks',
  },
  {
    title: `${t('task.title')} #${props.id}: ${task.value?.title ?? ''}`,
  },
]);

async function loadTask() {
  try {
    const response = await axios.get(appendIncludes(`/api/tasks/${props.id}`, includes));
    task.value = response.data.data;
  }
  catch (error) {
    console.error(error);
    showSnackMessage(t('task.cannot_load'));
  }
}

onMounted(() => {
  loadTask();
});
</script>

<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <div
      v-if="document"
    >
      <div class="text-subtitle-1">
        {{ document.name }}
      </div>
      <v-divider />
      <div class="text-body-1">
        {{ document.description }}
      </div>

      <v-btn
        :href="document.signed_file_url"
        color="primary"
        download
        prepend-icon="fas fa-download"
        :text="t('actions.download')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';

import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  id: string | number;
}>();

const { t } = useI18n();

const { showSnackMessage } = useErrorStore();
const breadcrumbs = [{ title: t('document.title') }];
const document = ref<App.Models.Document | null>(null);
const loadingCount = ref(0);

async function loadData() {
  loadingCount.value += 1;
  try {
    const response = await axios.get(`/api/documents/${props.id}`);
    document.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('document.cannot_load'));
  }
  finally {
    loadingCount.value -= 1;
  }
}

loadData();
</script>

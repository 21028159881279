import { useDataTableUtilities } from '@/composables';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAdminStore = defineStore('admin', () => {
  const { appendFilters } = useDataTableUtilities();
  const indexRoute = '/api/users';
  const users = ref([]);
  const locations = ref([]);

  async function loadAllUsers(filters: any = []) {
    users.value = [];

    let currentPage = 0;
    let lastPage = 1;
    while (currentPage < lastPage) {
      currentPage++;
      const queryData = {
        page: currentPage,
        per_page: 1000,
        basic: true,
      };

      try {
        const response = await axios.get(appendFilters(indexRoute, filters), { params: queryData });
        users.value = users.value.concat(response.data.data);
        lastPage = response.data.last_page;
      }
      catch (error) {
        console.error(error);
        break;
      }
    }
  }

  async function loadAllLocations(filters: any = []) {
    locations.value = [];

    let currentPage = 0;
    let lastPage = 1;
    while (currentPage < lastPage) {
      currentPage++;
      const queryData = {
        page: currentPage,
        per_page: 1000,
        basic: true,
      };

      try {
        const response = await axios.get(appendFilters('/api/locations', filters), { params: queryData });
        locations.value = locations.value.concat(response.data.data);
        lastPage = response.data.last_page;
      }
      catch (error) {
        console.error(error);
        break;
      }
    }
  }
  return {
    users,
    locations,
    loadAllUsers,
    loadAllLocations,
  };
});

import { ref } from 'vue';

const isMenuOpen = ref(true);
const isRailMode = ref(false);

export function useMenu() {
  function setMenuOpen() {
    isMenuOpen.value = true;
  }

  function setMenuClosed() {
    isMenuOpen.value = false;
  }

  function toggleMenuOpen() {
    isMenuOpen.value = !isMenuOpen.value;
  }

  function setRailModeOff() {
    isRailMode.value = false;
  }

  function toggleRailMode() {
    isRailMode.value = !isRailMode.value;
  }

  return {
    setMenuOpen,
    setMenuClosed,
    toggleMenuOpen,
    setRailModeOff,
    toggleRailMode,
    isMenuOpen,
    isRailMode,
  };
}

<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <v-card>
      <v-toolbar>
        <v-card-title>
          {{ faq.question }}
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <div v-html="faq.answer" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  id: string;
}>();
const { t } = useI18n();
const { showSnackMessage } = useErrorStore();

const breadcrumbs = [{ title: t('faq.long_title'), to: '/faqs' }, { title: t('faq.title') }];
const faq = ref<Partial<App.Models.Faq>>({ question: '', answer: '' });

async function loadData() {
  try {
    const result = await axios.get(`/api/public/faqs/${props.id}`);
    faq.value = result.data;
  }
  catch (error) {
    showSnackMessage(t('faq.cannot_load'));
  }
}

loadData();
</script>

export function useImageValidation() {
  const validateImage = (image, squareImage, maxImageKilobyteSize, minImageDimSize, minWidthImageDimSize, minHeightImageDimSize) => {
    const outcome = {
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      warningMessage: '',
    };

    const errorMessageConcatenator = () => ((outcome.errorMessage !== '') ? ' and ' : '');

    if (squareImage && image.width !== image.height) {
      const big = image.width > image.height ? image.width : image.height;
      const small = image.width < image.height ? image.width : image.height;
      const percentageDivergence = 100 - ((small / big) * 100);
      if (percentageDivergence > 10) {
        outcome.warningMessage += 'Your Image is not square, your image will be centre cropped. Please re-size for best results.';
        outcome.hasWarning = true;
      }
    }

    if (maxImageKilobyteSize > 0 && image.byteSize > (maxImageKilobyteSize * 1024)) {
      outcome.errorMessage += `${errorMessageConcatenator()}The Image specified is too large and must be a max size of
                                   ${maxImageKilobyteSize}KB. Your image is ${Math.ceil(image.byteSize / 1024)}KB`;
      outcome.hasError = true;
    }

    if (minImageDimSize > 0 && (minImageDimSize > image.width || minImageDimSize > image.height)) {
      outcome.errorMessage += `${errorMessageConcatenator()}Your image size must be at least ${minImageDimSize} x ${minImageDimSize}`;
      outcome.hasError = true;
    }

    if (minWidthImageDimSize > 0 && (minWidthImageDimSize > image.width)) {
      outcome.errorMessage += `${errorMessageConcatenator()}Your image width size must be at least ${minWidthImageDimSize}`;
      outcome.hasError = true;
    }

    if (minHeightImageDimSize > 0 && (minHeightImageDimSize > image.width)) {
      outcome.errorMessage += `${errorMessageConcatenator()}Your image height size must be at least ${minHeightImageDimSize}`;
      outcome.hasError = true;
    }

    return outcome;
  };

  return {
    validateImage,
  };
}

<template>
  <v-card
    class="fill-height text-center d-flex flex-column"
    rounded="lg"
  >
    <v-img
      max-height="200px"
      src="/images/widgets/default_current_needs.jpg"
      cover
    />
    <v-card-title class="text-primary-darken-1 text-h6 text-wrap">
      {{ t('shift.current_needs') }}
    </v-card-title>
    <v-card-text class="text-body-1">
      <p>
        {{ t('shift.current_needs_description') }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        to="/currentneeds"
        block
        size="large"
        :text="t('setting.current_needs_view')"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

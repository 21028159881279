<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/managing-shifts/my-shifts"
    >
      <template #append>
        <v-spacer />
        <div
          class="text-right align-top"
        >
          <v-btn
            v-if="!xs"
            size="large"
            color="secondary"
            prepend-icon="fas fa-plus"
            class="mb-2"
            :class="xs ? 'float-left' : 'float-right'"
            :text="t('smpw.submit_availability')"
            to="/mycalendar"
          />
        </div>
      </template>
    </breadcrumb-header>
    <div v-if="user">
      <v-alert
        v-if="user.profile_image_status == 'required' || user.profile_image_status == 'rejected'"
        type="error"
        class="mb-4"
      >
        <p v-if="user.profile_image_status == 'required'">
          {{ t('images.missing_profile_message') }}
        </p>
        <p v-else>
          {{ t('smpw.image_rejected_message') }}
        </p>
        <router-link
          to="/mydetails"
          class="btn btn-primary"
        >
          {{ t('global.view_profile') }}
        </router-link>
      </v-alert>
      <shift-list
        :user="user"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuth, useDataTableUtilities } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import { useMenuStore } from '@/stores/menu';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import ShiftList from '../ShiftList.vue';

const { xs } = useDisplay();
const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const { appendIncludes } = useDataTableUtilities();

const breadcrumbs: Array<BreadCrumb> = [
  {
    title: t('shift.mine'),
  },
];

const { authUser } = useAuth();
const user = ref<App.Models.User>();

const menuStore = useMenuStore();
const { unacknowledgedShiftsCount } = storeToRefs(menuStore);

async function loadData() {
  unacknowledgedShiftsCount.value = 0;
  const includes = ['unwantedLocations', 'profile_image_status'];
  const userId = authUser.id;
  const url = `/api/users/${userId}`;
  const realUrl = appendIncludes(url, includes);
  try {
    const response = await axios.get(realUrl);
    user.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('errors.cannot_load'));
  }
}

loadData();
</script>

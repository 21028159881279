<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    :fullscreen="xs"
  >
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-if="mode == 'create'"
        color="secondary"
        :size="size"
        v-bind="activatorProps"
        :text="t('task.new')"
        prepend-icon="fas fa-plus"
      />
      <v-btn
        v-if="mode == 'edit'"
        :size="size"
        color="primary"
        class="me-1"
        v-bind="activatorProps"
      >
        <v-icon icon="fas fa-edit" />
      </v-btn>
    </template>
    <task-edition-form
      :model-id="props.modelId"
      :mode="props.mode"
      @close="close"
      @model:created="reloadAndClose"
      @model:updated="reloadAndClose"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import TaskEditionForm from '@/components/tasks/TaskEditionForm.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const props = withDefaults(defineProps<{
  modelId?: number;
  mode: 'edit' | 'create' | 'show';
  size?: string;
}>(), {
  size: () => 'small',
});

const emit = defineEmits(['reload']);

const { xs } = useDisplay();

const { t } = useI18n();
const dialog = ref(false);

function close() {
  dialog.value = false;
}

function reloadAndClose() {
  emit('reload');
  close();
}
</script>

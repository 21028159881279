<template>
  <v-menu
    v-model="columnMenu"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        icon="fas fa-table-columns"
        color="primary"
      />
    </template>
    <v-card min-width="300">
      <v-list
        v-model:selected="selected"
        select-strategy="classic"
      >
        <v-list-item
          v-for="header in hideableHeaders"
          :key="header.key"
          :value="header.key"
        >
          <template #prepend="{ isActive }">
            <v-list-item-action start>
              <v-checkbox-btn
                :model-value="isActive"
                color="primary"
              />
            </v-list-item-action>
          </template>
          <v-list-item-title class="text-font">
            {{ header.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import type {
  ComputedRef,
} from 'vue';
import {
  computed,
  ref,
} from 'vue';

const properties = defineProps<{
  headers: Array<any>;
}>();

const selected = defineModel<Array<any>>({ default: [] });

const columnMenu = ref(false);

const hideableHeaders: ComputedRef<Array<any>> = computed(() => properties.headers
  .filter((header) => header.key !== 'actions')
  .map((header) => ({ ...header, value: header.key })));
</script>

const routes = [
  {
    path: '/admin',
    name: 'global.admin',
    component: async () => import('@/components/AdminMenu.vue'),
  },
  {
    path: '/admin/timebands',
    name: 'z.timebands',
    component: async () => import('@/components/shifttimeband/ShiftTimeBandDataTable.vue'),
  },
  {
    path: '/admin/reports/:id',
    name: 'z.view_report',
    component: async () => import('@/components/reports/ReportShow.vue'),
    props: true,
    meta: {
      permission: [
        'manage-reports',
        'access-reports',
      ],
    },
  },
  {
    path: '/admin/reports',
    name: 'z.reports',
    component: async () => import('@/components/reports/ReportDataTable.vue'),
    meta: {
      permission: [
        'manage-reports',
        'access-reports',
      ],
    },
  },
  {
    path: '/admin/dayoverseers',
    name: 'z.day_overseers',
    component: async () => import('@/components/dayoverseers/DayOverseerIndex.vue'),
    meta: {
      permission: 'manage-dayoverseers',
    },
  },
  {
    path: '/admin/settings',
    name: 'z.settings',
    component: async () => import('@/components/settings/SettingsAdmin.vue'),
    meta: { permission: 'manage-settings' },
  },
  {
    path: '/admin/shiftplans',
    name: 'z.shift_plans',
    component: async () => import('@/components/shiftplan/ShiftPlanIndex.vue'),
    meta: { permission: 'manage-shift-plans' },
  },
  {
    path: '/admin/usersimport',
    name: 'z.import_users',
    component: async () => import('@/components/users/UserImport.vue'),
    meta: { permission: 'import-users' },
  },
  {
    path: '/admin/training/attendees/:id',
    name: 'course.course_attendees',
    component: async () => import('@/components/coursesessions/CourseSessionAttendees.vue'),
    props: true,
    meta: { permission: 'manage-course-sessions' },
  },
  {
    path: '/admin/experiences',
    name: 'z.experiences',
    component: async () => import('@/components/experiences/ExperienceAdmin.vue'),
    meta: { permission: 'manage-experiences' },
  },
  {
    path: '/admin/updates',
    name: 'z.update',
    component: async () => import('@/components/updates/UpdateManager.vue'),
    meta: { permission: 'update-application' },
  },
  {
    path: '/admin/news',
    name: 'z.news',
    component: async () => import('@/components/news/NewsDataTable.vue'),
    meta: { permission: 'manage-news' },
  },
  {
    path: '/admin/teams',
    name: 'z.teams',
    component: async () => import('@/components/teams/TeamIndex.vue'),
    meta: {
      permission: [
        'manage-teams',
        'read-teams',
      ],
    },
  },
  {
    path: '/admin/locations',
    name: 'z.locations',
    component: async () => import('@/components/locations/LocationIndex.vue'),
    meta: { permission: 'manage-locations' },
  },
  {
    path: '/admin/faqs',
    name: 'z.faqs',
    component: async () => import('@/components/faqs/FaqAdmin.vue'),
    meta: { permission: 'manage-faqs' },
  },
  {
    path: '/admin/training',
    name: 'z.training',
    component: async () => import('@/components/TrainingIndex.vue'),
    meta: {
      permission: [
        'manage-courses',
        'manage-course-sessions',
      ],
    },
  },
  {
    path: '/admin/sysinfo',
    name: 'z.system_info',
    component: async () => import('@/components/SysInfo.vue'),
    meta: { permission: 'access-system-monitor' },
  },
  {
    path: '/admin/news/:id',
    name: 'z.view_article',
    component: async () => import('@/components/news/NewsPreview.vue'),
    props: true,
    meta: { permission: 'manage-news' },
  },
  {
    path: '/admin/documents',
    name: 'z.documents',
    component: async () => import('@/components/documents/DocumentDataTable.vue'),
    meta: { permission: 'manage-documents' },
  },
  {
    path: '/admin/circuits',
    name: 'z.circuits',
    component: async () => import('@/components/circuits/CircuitDataTable.vue'),
    meta: { permission: 'manage-circuits' },
  },
  {
    path: '/admin/congregations',
    name: 'z.congregations',
    component: async () => import('@/components/congregations/CongregationIndex.vue'),
    meta: {
      permission: [
        'manage-congregations',
        'manage-circuit-congregations',
      ],
    },
  },
  {
    path: '/admin/stores',
    name: 'z.stores',
    component: async () => import('@/components/stores/StoreIndex.vue'),
    meta: { permission: 'manage-stores' },
  },
  {
    path: '/admin/shifttags',
    name: 'z.shift_tags',
    component: async () => import('@/components/shifttags/ShiftTagDataTable.vue'),
    meta: { permission: 'manage-shift-tags' },
  },
  {
    path: '/admin/googleaccounts',
    name: 'z.google_accounts',
    component: async () => import('@/components/googleaccount/GoogleAccountDataTable.vue'),
  },
  {
    path: '/admin/shiftcancellationreasons',
    name: 'z.cancellation_reasons',
    component: async () => import('@/components/shiftcancellationreasons/ShiftCancellationReasonsDataTable.vue'),
    meta: { permission: 'manage-shift-cancellation-reasons' },
  },
  {
    path: '/admin/taskcategories',
    name: 'z.task_categories',
    component: async () => import('@/components/taskcategories/TaskCategoryDataTable.vue'),
    meta: { permission: ['manage-task-categories'] },
  },
  {
    path: '/admin/videos',
    name: 'z.videos',
    component: async () => import('@/components/videos/VideoDataTable.vue'),
    meta: { permission: 'manage-videos' },
  },
  {
    path: '/admin/userratinglabels',
    name: 'z.user_rating_labels',
    component: async () => import('@/components/userratinglabels/UserRatingLabelDataTable.vue'),
    meta: {
      permission: [
        'manage-users',
        'manage-shifts',
        'manage-user-ratings',
        'manage-user-rating-labels',
      ],
    },
  },
  {
    path: '/admin/languages',
    name: 'z.languages',
    component: async () => import('@/components/languages/LanguageDataTable.vue'),
    meta: { permission: 'manage-languages' },
  },
  {
    path: '/admin/contactmessageoptions',
    name: 'z.contact_options',
    component: async () => import('@/components/contactmessageoptions/ContactMessageOptionDataTable.vue'),
    meta: {
      permission: 'manage-contact-message-options',
    },
  },
  {
    path: '/admin/carts',
    name: 'z.carts',
    component: async () => import('@/components/carts/CartDataTable.vue'),
    meta: { permission: 'manage-cart' },
  },
  {
    path: '/admin/alertmessages',
    name: 'z.alerts',
    component: async () => import('@/components/alertmessages/AlertMessageDataTable.vue'),
    meta: { permission: 'manage-alert-messages' },
  },
  {
    path: '/admin/publications',
    name: 'z.publications',
    component: async () => import('@/components/publications/PublicationDataTable.vue'),
    meta: { permission: 'manage-publications' },
  },
  {
    path: '/admin/roles',
    name: 'z.roles_and_permissions',
    component: async () => import('@/components/roles/RoleDataTable.vue'),
    meta: { permission: 'manage-roles' },
  },
  {
    path: '/admin/shifts/schedule',
    name: 'z.schedule',
    component: async () => import('@/components/scheduler/ShiftScheduler.vue'),
  },
  {
    path: '/admin/users',
    name: 'z.users',
    component: async () => import('@/components/users/UserIndex.vue'),
    meta: {
      permission: [
        'manage-users',
        'manage-circuit-users',
      ],
    },
  },
  {
    path: '/admin/users/:id',
    name: 'z.view_user',
    component: async () => import('@/components/users/UserShow.vue'),
    props: true,
    meta: {
      permission: [
        'manage-users',
        'manage-circuit-users',
      ],
    },
  },
  {
    path: '/admin/users/:id/edit',
    component: async () => import('@/components/users/UserEditionFormWrapper.vue'),
    name: 'z.edit_user',
    props: (route) => ({ modelId: route.params.id, mode: 'edit' }),
  },
];

export default routes;

<template>
  <v-footer
    :class="xs ? '' : 'bg-light-grey'"
  >
    <v-row>
      <v-col>
        <div class="text-medium-emphasis text-right login_footer">
          <slot />
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';

const { xs } = useDisplay();
</script>

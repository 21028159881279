<template>
  <v-list-item
    :to="to"
    :href="href"
    :title="title"
    min-height="35px"
    @click="emit('click')"
  >
    <template #prepend>
      <div v-if="hasPillContent">
        <v-badge
          color="secondary"
          :content="pill"
          class="me-4"
          floating
          text-color="#ffffff"
        >
          <v-icon
            :icon="icon"
            size="x-small"
          />
        </v-badge>
      </div>

      <v-icon
        v-else
        class="me-n4"
        :icon="icon"
        size="x-small"
      />
    </template>
  </v-list-item>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  icon?: string;
  target?: string;
  to?: string;
  href?: string;
  title: string;
  pill?: number;
}>();

const emit = defineEmits(['click']);

const hasPillContent = computed(() => props.pill && !Number.isNaN(props.pill) && props.pill > 0);
</script>

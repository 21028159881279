<template>
  <v-dialog
    v-model="deleteAvailabilityDialog"
    max-width="500px"
  >
    <global-card
      heading-color="error"
      :title="t('smpw.delete_availability')"
    >
      <h5>{{ t('shift.deletion.message_1', { date: toLongDateWithDay(date) }) }}</h5>

      <template #actions>
        <v-btn
          :text="t('actions.close')"
          color="dark-grey"
          @click="close()"
        />
        <v-btn
          color="error"
          :text="t('actions.delete')"
          @click="deleteAvailability"
        />
      </template>
    </global-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  date?: NullableDateOptionType;
  user: App.Models.User;
}>();

const emit = defineEmits(['availability:deleted']);

const { t } = useI18n();

const deleteAvailabilityDialog = defineModel<boolean>({ default: false });

const {
  toUniversalDate,
  toLongDateWithDay,
} = useDate();

const { showSnackMessage } = useErrorStore();

function close() {
  deleteAvailabilityDialog.value = false;
}

async function deleteAvailability() {
  const date = toUniversalDate(props.date);
  try {
    await axios.delete(
      `/api/users/${props.user.id}/shifts/availability`,
      { params: { date } },
    );
    close();
    showSnackMessage(t('shift.deletion.success_message'), 'success');
    emit('availability:deleted', props.date);
  }
  catch (error) {
    showSnackMessage(t('shift.deletion.error_message'));
  }
}
</script>

<template>
  <model-edition-form
    ref="form"
    v-model="team"
    :model-name="t('team.title')"
    base-url="/api/teams"
    :model-id="props.modelId"
    :mode="props.mode"
    :with-text="false"
    @close="emit('close')"
    @model:updated="emit('model:updated')"
    @model:created="emit('model:created')"
  >
    <v-tabs
      v-model="tab"
      class="ms-4 mt-2"
    >
      <smpw-tab
        value="info"
        :active="tab === 'info'"
        :text="t('team.title')"
      />
      <smpw-tab
        value="members"
        :active="tab === 'members'"
        :text="t('role.member', 2)"
      />
    </v-tabs>

    <v-window
      v-model="tab"
      :touch="false"
    >
      <v-window-item value="info">
        <v-container>
          <v-text-field
            v-model="team.name"
            :readonly="readOnly"
            :label="t('global.name')"
            :error="!!validationErrors.name"
            :error-messages="validationErrors.name"
          />
          <user-select
            v-model="/** @ts-ignore */team.overseer_user_id"
            :disabled="readOnly"
            :label="t('team.overseer')"
            :error="!!validationErrors.overseer_user_id"
            :error-messages="validationErrors.overseer_user_id"
            active-only
          />

          <rich-text-editor
            v-model="team.description"
            :label="t('global.description')"
            :error="!!validationErrors.description"
            :error-messages="validationErrors.description"
          />

          <v-checkbox
            v-model="team.description_visible"
            :label="t('team.allow_all_members_hint')"
            :readonly="readOnly"
            :error="!!validationErrors.description_visible"
            :error-messages="validationErrors.description_visible"
          />
        </v-container>
      </v-window-item>
      <v-window-item value="members">
        <team-member-selector v-model="/** @ts-ignore */team.members" />
      </v-window-item>
    </v-window>
  </model-edition-form>
</template>

<script setup lang="ts">
import { useAdminStore } from '@/stores/admin';
import { useErrorStore } from '@/stores/errors';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ModelEditionForm from '../ModelEditionForm.vue';
import RichTextEditor from '../RichTextEditor.vue';
import UserSelect from '../users/UserSelect.vue';
import TeamMemberSelector from './TeamMemberSelector.vue';

const props = defineProps<{
  modelId?: number;
  mode: 'edit' | 'create' | 'show';
}>();
const emit = defineEmits(['model:updated', 'model:created', 'model:loaded', 'error:handled', 'close']);
const { t } = useI18n();
const tab = ref('info');

const readOnly = computed(() => props.mode === 'show');
const defaultItem = {
  id: -1,
  name: '',
  description: '',
  overseer_user_id: -1,
  members: [],
  description_visible: false,
  created_at: null,
  created_by: null,
  updated_at: null,
  updated_by: null,
};

const team = ref<App.Models.Team>(defaultItem);
const form = ref();
const errorStore = useErrorStore();
const { loadAllUsers } = useAdminStore();

const { validationErrors } = storeToRefs(errorStore);
watch(team, () => {
  form.value.validate();
}, { deep: true });
loadAllUsers([{
  column: 'active',
  operator: '=',
  value: 1,
}]);
</script>

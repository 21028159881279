<template>
  <div>
    <div v-if="authUser">
      <breadcrumb-header :items="breadcrumbs" />
    </div>
    <div v-else>
      <global-header :title="t('policies.privacy')" />
    </div>
    <v-container
      class="tiptap text-font"
    >
      <div v-html="getSetting('privacy_policy')" />
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useAuth, useSettings } from '@/composables';
import { useI18n } from 'vue-i18n';
import GlobalHeader from './GlobalHeader.vue';

const { t } = useI18n();
const { getSetting } = useSettings();
const { authUser } = useAuth();

const breadcrumbs: Array<BreadCrumb> = [
  {
    title: t('policies.privacy'),
  },
];
</script>

<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/before-shifts/current-carts"
    />
    <div v-if="!loading && carts.length > 0">
      <div class="mt-4 mb-4">
        <v-icon
          color="grey-darken-1"
          icon="fas fa-filter"
          class="me-2 ms-2"
          size="x-small"
        />
        <v-btn
          v-for="language in languages"
          :key="language.id"
          :text="language.name"
          class="me-1 mb-1"
          :color="!hasLanguageFilter(language) ? 'primary-lighten-1 text-primary-darken-1' : 'light-grey text-font'"
          @click="toggleLanguageFilter(language)"
        />
        <v-icon
          color="grey-darken-1"
          icon="fas fa-minus"
          class="me-2 ms-2"
          size="x-small"
        />
        <v-btn
          class="me-1 mb-1 text-primary-darken-1"
          :color="shouldShowAllLanguages ? 'primary-lighten-1 text-primary-darken-1' : 'light-grey text-font'"
          :text="t('actions.show_all')"
          @click="clearLanguageFilter"
        />
      </div>

      <div
        v-for="cartGroup in displayedFilteredCarts"
        :key="cartGroup.language.id"
        class="mb-4"
      >
        <!-- Cart Language Section -->
        <v-toolbar
          color="light-grey"
          density="compact"
        >
          <v-card-title class="text-h5">
            {{ cartGroup.language.name }}
          </v-card-title>
        </v-toolbar>

        <!-- Cart Wrapper -->
        <v-card-text>
          <v-row>
            <!-- Cart -->
            <v-col
              v-for="cart in cartGroup.carts"
              :key="cart.id"
              cols="12"
              lg="6"
            >
              <v-card>
                <v-card-title class="bg-primary-lighten-1 mb-2 py-3">
                  <span class="text-h6 text-wrap">
                    {{ cart.name }}
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-row class="d-flex flex-column flex-sm-row">
                    <v-col sm="4">
                      <v-card
                        color="light-grey"
                        variant="flat"
                        class="pa-2"
                      >
                        <image-slideshow
                          v-if="cart.image && cart.image.signed_file_url"
                          :images="[{ href: cart.image.signed_file_url }]"
                          :show-arrows="false"
                          can-zoom
                        />
                        <v-card-text
                          v-else
                          class="text-primary-darken-1"
                        >
                          {{ t('images.none_available') }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col sm="8">
                      <v-list>
                        <v-list-item class="text-h6 text-primary-darken-1">
                          {{ t('date.from_to', { start: toLongDate(cart.start_date), end: toLongDate(cart.end_date) }) }}
                        </v-list-item>
                        <v-divider />
                        <v-list-item
                          v-if="cart.poster"
                          variant="flat"
                          base-color="primary-lighten-1"
                          class="text-primary-darken-1"
                        >
                          <strong>{{ t('cart.board') }}:</strong>
                          {{ cart.poster.title }}
                        </v-list-item>
                        <v-divider />
                        <v-list-item
                          v-if="cart.row1"
                          class="text-primary-darken-1"
                        >
                          <strong>{{ t('cart.row_n', { num: 1 }) }}:</strong> {{ cart.row1.title }}
                        </v-list-item>
                        <v-divider />
                        <v-list-item
                          v-if="cart.row2"
                          class="text-primary-darken-1"
                        >
                          <strong>{{ t('cart.row_n', { num: 2 }) }}:</strong> {{ cart.row2.title }}
                        </v-list-item>
                        <v-divider />
                        <v-list-item
                          v-if="cart.row3"
                          class="text-primary-darken-1"
                        >
                          <strong>{{ t('cart.row_n', { num: 3 }) }}:</strong> {{ cart.row3.title }}
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- End of Cart Language Section -->
          </v-row>
        </v-card-text>
      </div>
    </div>
    <div
      v-else-if="!loading && carts.length === 0"
    >
      <div class="text-center text-grey pa-5">
        {{ t('cart.none_found') }}
      </div>
    </div>
    <div
      v-else
    >
      <div class="text-center pa-5">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ImageSlideshow from '../images/ImageSlideshow.vue';

const { t } = useI18n();
const breadcrumbs = [{ title: t('cart.current') }];

const { showSnackMessage } = useErrorStore();
const { toLongDate } = useDate();
const languages = ref<App.Models.Language[]>([]);
const selectedLanguages = ref<App.Models.Language[]>([]); // Set of language id);
const loading = ref(0);
const carts = ref<Array<App.Models.Cart>>([]);

function sortLanguages(a, b) {
  const x = a.name.toLowerCase();
  const y = b.name.toLowerCase();

  if (x > y)
    return 1;
  if (x < y)
    return -1;

  return 0;
}

interface FilteredCarts {
  carts: App.Models.Cart[];
  language: App.Models.Language;
}

const shouldShowAllLanguages = computed(() => selectedLanguages.value.length === 0);

const groupCartsByLanguage = computed<FilteredCarts[]>(() => {
  const cartsGroupedByLanguage: any = [];
  languages.value.forEach((language) => {
    cartsGroupedByLanguage.push({
      language,
      carts: carts.value.filter((a) => a?.language?.id === language.id),
    });
  });
  return cartsGroupedByLanguage.sort((a, b) => sortLanguages(a.language, b.language));
});

/**
 * Return the carts that match the selected languages
 */
const displayedFilteredCarts = computed<FilteredCarts[]>(() => {
  if (shouldShowAllLanguages.value) {
    return groupCartsByLanguage.value;
  }
  /* @ts-ignore */
  return groupCartsByLanguage.value.filter((a) => selectedLanguages.value.includes(a.language.id));
});

function clearLanguageFilter() {
  selectedLanguages.value = [];
}

function hasLanguage(language) {
  return languages.value.some((l) => l.id === language.id);
}

/**
 * Set the languages array based on the carts
 */
function setLanguages() {
  languages.value = [];
  carts.value.forEach((cart) => {
    if (!hasLanguage(cart.language)) {
      /* @ts-ignore */
      languages.value.push(cart.language);
    }
  });
  languages.value = languages.value.sort(sortLanguages);
}

function hasLanguageFilter(language) {
  return selectedLanguages.value.includes(language.id);
}

function toggleLanguageFilter(language) {
  if (selectedLanguages.value.includes(language.id)) {
    selectedLanguages.value = selectedLanguages.value.filter((l) => l !== language.id);
  }
  else {
    selectedLanguages.value.push(language.id);
  }
}

async function loadInitialData() {
  loading.value += 1;
  try {
    const response = await axios.get('/api/carts/current');
    carts.value = response.data.data;
    setLanguages();
  }
  catch (error) {
    console.error(error);
    showSnackMessage(t('cart.cannot_load'));
  }
  finally {
    loading.value -= 1;
  }
}
onMounted(() => {
  loadInitialData();
});
</script>

<style scoped>
.filter_button_inactive {
    opacity: 0.7;
}
</style>

<template>
  <section>
    <div class="text-h5">
      {{ t('news.archive') }}
    </div>
    <v-divider />
    <vuetify-data-table
      :model-name="t('news.archive')"
      :headers="headers"
      url="/api/news/archived"
      :edit-enabled="false"
      :create-enabled="false"
      :delete-enabled="false"
      :additional-actions="additionalActions"
    />
  </section>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useI18n } from 'vue-i18n';
import VuetifyDataTable from '../crud/VuetifyDataTable.vue';
import NewsDialog from './NewsDialog.vue';

const { t } = useI18n();
const { toUniversalDate } = useDate();

const headers = [
  {
    title: t('global.id'),
    value: 'id',
  },
  {
    title: t('global.title'),
    value: 'title',
  },
  {
    title: t('date.publication'),
    key: 'publication_date',
    value: (data) => (toUniversalDate(data.publication_date)),
  },
  {
    title: t('global.action', 2),
    value: 'actions',
  },
];

const additionalActions = [{
  component: NewsDialog,
}];
</script>

<template>
  <advanced-marker
    :options="{
      position: position,
      title: title,
      gmpDraggable: !readOnly,
    }"
    :pin-options="{
      glyph: markerImageToUse,
      scale: 1,
    }"
    @dragend="updateLocation"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AdvancedMarker } from 'vue3-google-map';

const props = withDefaults(defineProps<{
  // position: google.maps.LatLngLiteral;
  position: any;
  readOnly?: boolean;
  title?: string;
  markerImageUrl: string;
}>(), {
  readOnly: true,
  title: 'undefined',
});

const emit = defineEmits(['update:position']);

const markerImageToUse = computed(() => {
  const m = document.createElement('img');
  m.src = props.markerImageUrl ?? '/images/mapmarkers/pin_meet_1.png';
  m.style.maxHeight = '48px';
  return m;
});

function updateLocation(e) {
  emit('update:position', e);
}
</script>
